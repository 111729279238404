<template>
    <div
      class="modal fade"
      id="modalAddUsername"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddUsernameLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-3 radius-1r">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5 class="modal-title" id="modalAddUsernameLabel">{{$t('mdt_room.lbl_enter_fullName')}}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
              @click="$emit('onClose')"
            >
              <span aria-hidden="true" class="fs-18 fw-500">&times;</span>
            </button>
          </div>
          <div class="mb-3">
            <p class="fs-16 fw-600 txt-blur-gray mb-2">
              {{$t('mdt_room.lbl_fullname')}}
              <span class="text-danger">*</span>
            </p>
            <input
              type="text"
              v-model="username"
              class="border radius-1r form-control bg-white py-2"
            />
          </div>
          <div class="mb-3">
            <button
              :disabled="!username || isLoading"
              class="btn-action btn btn-primary bg-pri bd-pri w-100"
              @click="handleSaveName"
            >{{$t('mdt_room.btn_save')}}</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import appUtils from '../../utils/appUtils'

export default {
  name: 'modalAddUsername',
  data () {
    return {
      username: '',
      isLoading: false
    }
  },
  mounted () {
    if (this.$user.email && !this.$user.name) {
      this.username = this.$user.email
    }
  },
  methods: {
    async handleSaveName () {
      const self = this
      const formData = new FormData()
      formData.set('name', this.username)

      try {
        self.isLoading = true
        await self.$rf.getRequest('DoctorRequest').updateDoctorMe(formData)
        const response = await self.$rf.getRequest('AuthRequest').me()
        if (response) {
          appUtils.setLocalUser(response.data)
          const lang = appUtils.getLangCode(response.data.lang) || 'vi'
          this.$lang = lang
          appUtils.setLocalLang(lang)
        }
        this.$toast.open({
          message: this.$t('mdt_room.lbl_success_msg'),
          type: 'success'
        })
        this.$emit('onClose')
      } catch (error) {
        console.log(error)
      } finally {
        self.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>