<template>
  <div class="h-100vh" :class="step == 2 ? '' : ''">
    <Navbar v-if="step == 1" />
    <!-- unlocked -->
    <div v-if="!loading">
      <div v-if="step == 1 && !loading">
        <div class="town-hall">
          <div class="town-hall-track">
            <PrepareTrack
              :localStream="local_stream"
              :hasAudio="has_audio"
              :hasVideo="has_video"
              :camStatus="cam_status"
              :micStatus="mic_status"
              @camChangeStatus="camChangeStatus"
              @micChangeStatus="micChangeStatus"
              @testSpk="testSpk"
              @startVideo="createLocalTracks"
            />
          </div>
          <div class="town-hall-info text-center" v-if="mdt_room">
            <p class="text-center fs-24 fw-500 mb-1">{{ mdt_room.name }}</p>
            <p class="text-center fs-18">{{ mdtUtils.getTimeStringDetail(mdt_room) }}</p>
            <!-- <p class="text-center fs-16 fw-500">
            Không có người nào khác ở đây
            <span class="fs-12 fw-400">(chức năng đang phát triển)</span>
            </p>-->
            <button
              class="btn btn-primary bg-pri bd-pri me-2"
              v-if="mdt_room"
              @click="join()"
            >{{$t('meet_room.btn_join')}}</button>
            <!-- <button
              v-if="mdt_room"
              class="btn btn-light txt-pri box-shadow"
              @click="onShareScreen()"
              :disabled="!canScreenShare()"
            >
              Trình bày
            </button>-->
          </div>
        </div>
      </div>
      <div v-if="step == 2 && !loading" class="main-content">
        <div v-show="show_type == 'slide'" class="main-content-item">
          <SlideShow
            :room="mdt_room"
            :diseases="public_mdt_cases.data"
            :type="'ts'"
            :loading="loading_current_disease"
            :present_language="present_language"
            :is_player="user_member "
            :showing_id="showing_id"
            :camStatus="cam_status"
            :micStatus="mic_status"
            :hasAudio="has_audio"
            :hasVideo="has_video"
            :changing_cam="changing_cam"
            :changing_mic="changing_mic"
            :indicator="indicator"
            :is_master="user_member"
            :checkin_members="checkin_members"
            @showParticipant="show_participant = !show_participant"
            @showInfo="showInfo"
            @showFunctions="showFunctions"
            @setShowingId="setShowingId"
            @camChangeStatus="camChangeStatusSlideShow"
            @micChangeStatus="micChangeStatusSlideShow"
            @onDisconect="onDisconect"
            @setSlideShow="setSlideShow"
            @refresh="onRefreshMembers"
            @refreshCase="refreshCase"
            @changeShowType="changeShowType"
          />
        </div>
        <div v-show="show_type == 'bb'">
          <ConcludingForm
            v-if="showingCase"
            @changeShowType="changeShowType"
            @changeConcludingForm="changeConcludingForm"
            @refresh="getMdtCases"
            :currCase="showingCase"
            :checkin_members="checkin_members"
            :room_info="mdt_room"
            :prop_form="concluding_data.form"
            :prop_el_id="concluding_data.id"
          />
        </div>
        <div v-show="show_type == 'tqm'">
          <TQMForm
            v-if="showingCase"
            :is_player="user_member && user_member.role === 4"
            :currCase="showingCase"
            @changeShowType="changeShowType"
            @changeOperationForm="changeOperationForm"
            @refresh="getMdtCases"
            :prop_form="operation_data.form"
            :prop_el_id="operation_data.id"
            :room_info="mdt_room"
          />
        </div>
        <div>
          <MdtHoDoConf
            :hasAudio="has_audio"
            :hasVideo="has_video"
            :camStatus="cam_status"
            :micStatus="mic_status"
            :screenStatus="screen_status"
            :room_info="mdt_room"
            :room_name="$route.params.name"
            :room_members="room_members"
            :show_info="show_info"
            :show_function="show_function"
            :disconect="disconect"
            :changing_cam="changing_cam"
            :changing_mic="changing_mic"
            :show_participant="show_participant"
            :show_slide="show_slide"
            :camStatusSlideShow="camStatusSlideShow"
            :micStatusSlideShow="micStatusSlideShow"
            :present_language="present_language"
            @setChangingMic="setChangingMic"
            @setChangingCam="setChangingCam"
            @camChangeStatus="camChangeStatus"
            @micChangeStatus="micChangeStatus"
            @showInfo="showInfo"
            @showFunctions="showFunctions"
            @disconected="backToList"
            @hide="show_participant = false"
            @participantsChange="participantsChange"
          />
        </div>
      </div>
      <div v-if="loading" class="loading-meet-room">
        <img src="../../../public/assets/images/gif/Loading.gif" width="212" height="212" alt />
      </div>
      <!-- hiden content -->
      <div class="info-wraper box-shadow" v-show="show_info">
        <div class="info-wraper-backdrop" @click="show_info ^= show_info"></div>
        <div class="info-wraper-content">
          <PublicMdtInfo
            :mdt_room="mdt_room"
            :room_members="room_members"
            :checkin_members="checkin_members"
            :participants="participants"
            @hideInfo="hideInfo"
          />
        </div>
      </div>
      <div class="info-wraper box-shadow" v-show="show_function">
        <div class="info-wraper-backdrop" @click="show_function ^= show_function"></div>
        <div class="info-wraper-content">
          <PublicMdtCases
            :mdt_cases="public_mdt_cases"
            :showing_id="showing_id"
            :loading="loading_current_disease"
            @hideDisease="hideFunctions"
            @refresh="refreshCase"
          />
        </div>
      </div>
      <div class="audio d-none">
        <audio ref="audioTest" id="spk-test" preload="auto">
          <source src="../../../public/assets/audio/test_spk.mp3" type="audio/mp3" />
        </audio>
      </div>
    </div>
    <!-- unlocked -->

    <SupportBrowser />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'

import Navbar from '../../components/layouts/Navbar.vue'
import SupportBrowser from '../../components/PopupNotification/SupportBrowser.vue'
import PublicMdtInfo from '../../components/publicMdts/PublicMdtInfo.vue'
import SlideShow from '../../components/Ultis/SlideShow.vue'
import PrepareTrack from '../../components/meets/PrepareTrack.vue'
import MdtHoDoConf from '../../components/TwilioComponent/MdtHoDoConf.vue'
import PublicMdtCases from '../../components/publicMdts/PublicMdtCases.vue'
import ConcludingForm from '../../components/publicMdts/concludings/ConcludingForm.vue'
import TQMForm from '../../components/publicMdts/operations/TQMForm.vue'

// show_type:
// 1 slide
// 2 bien ban
// 3 thong qua mo

export default {
  name: 'PublicMdtRoom',
  components: {
    Navbar,
    SupportBrowser,
    PublicMdtInfo,
    PrepareTrack,
    MdtHoDoConf,
    SlideShow,
    PublicMdtCases,
    ConcludingForm,
    TQMForm
  },
  data () {
    return {
      mdt_room: null,
      room_members: [],
      checkin_members: [],
      public_mdt_cases: [],
      step: 1,
      loading: false,
      mdtUtils,
      params_case: {
        sort: 'created_at',
        sort_by: 'asc',
        status: null,
        search: '',
        page: 0,
        loading: false
      },
      whisper_room: null,
      room_name: '',
      is_support_browser: false,
      has_video: false,
      has_audio: false,
      has_devices: [],
      show_info: false,
      show_function: false,
      mic_status: false,
      cam_status: false,
      screen_status: false,
      local_stream: null,
      show_participant: false,
      showing_id: null,
      loading_current_disease: false,
      disconect: 0,
      user_member: null,
      user_id: null,
      changing_mic: true,
      changing_cam: true,
      participants: [],
      show_type: 'slide',
      concluding_data: {
        form: null,
        id: null
      },
      operation_data: {
        form: null,
        id: null
      },
      indicator: null,
      present_language: 'vi',
      micStatusSlideShow: false,
      camStatusSlideShow: false,
      show_slide: true
    }
  },
  watch: {
    $echo (e) {
      if (e && this.whisper_room) {
        this.onConnectSocket()
      }
    }
  },
  computed: {
    showingCase () {
      if (
        !this.public_mdt_cases ||
        !this.public_mdt_cases.total ||
        !this.showing_id
      ) {
        return {}
      }
      const sc = this.public_mdt_cases.data.find(c => this.showing_id === c.id)
      return sc
    }
  },
  mounted () {
    const self = this
    this.present_language = this.$lang || 'vi'
    self.loading = true
    if (!this.$user) {
      this.$targetRoute = `/#/pmdt/${this.$route.params.name}`
      this.$router.push({ name: 'Login' })
      this.loading = false
      return false
    }
    if (!this.$route.params.name) {
      this.backToList()
      self.loading = false
      return false
    }
    this.room_name = this.$route.params.name
    this.user_id = this.$user?.id
    this.$nextTick(function () {
      window.$('#modalSuportBrowser').on('hidden.bs.modal', function (e) {
        self.is_support_browser = false
        self.backToList()
      })
    })
    self.has_devices = []
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      self.onShowModalSpBr(true)
    } else {
      self.is_support_browser = true
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          devices.forEach(device => {
            self.has_devices.push(device.kind)
          })
        })
        .then(() => {
          self.has_video = !!self.has_devices.includes('videoinput')
          self.has_audio = !!self.has_devices.includes('audioinput')
          if (
            !navigator.userAgent.search('Safari') >= 0 ||
            !navigator.userAgent.search('Chrome') < 0
          ) {
            self.showPublicMdtRoomByName(self.$route.params.name)
          }
        })
        .catch(function (err) {
          console.log(err.name + ': ' + err.message)
        })
    }
  },
  methods: {
    changeConcludingForm (form, id) {
      this.concluding_data = {
        form: form,
        id: id || this.concluding_data.id
      }
      this.indicator = ''
      this.whisper_room.whisper('changeConcludingForm', {
        id: this.$user.id,
        name: this.$user?.name,
        role: this.user_member?.role,
        el_id: id,
        form: form
      })
      // console.log('sendChangeConcludingForm', {
      //   id: this.$user.id,
      //   name: this.$user?.name,
      //   role: this.user_member?.role,
      //   el_id: id,
      //   form: form
      // })
    },
    changeOperationForm (form, id) {
      this.operation_data = {
        form: form,
        id: id || this.operation_data.id
      }
      this.indicator = ''
      this.whisper_room.whisper('changeOperationForm', {
        id: this.$user.id,
        name: this.$user?.name,
        role: this.user_member?.role,
        el_id: id,
        form: form
      })
      // console.log('sendchangeOperationForm', {
      //   id: this.$user.id,
      //   name: this.$user?.name,
      //   role: this.user_member?.role,
      //   el_id: id,
      //   form: form
      // })
    },
    changeShowType (type) {
      if (type && (type === 'slide' || type === 'bb' || type === 'tqm')) {
        this.indicator = ''
        this.show_type = type
        this.whisper_room.whisper('changeShowType', {
          id: this.$user.id,
          name: this.$user?.name,
          role: this.user_member?.role,
          type: type
        })
        // console.log('sendChangeShowType', {
        //   id: this.$user.id,
        //   name: this.$user?.name,
        //   role: this.user_member?.role,
        //   type: type
        // })
      }
    },
    participantsChange (ps) {
      this.participants = ps
    },
    backToList () {
      const user = this.$user
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.hodo.app/#/organization'
      } else {
        dm = `${user?.domain || 'https://hodo.app'}/#/organization`
      }
      window.location.href = dm
    },
    //
    async showPublicMdtRoomByName (name) {
      const self = this
      if (!name) return
      self.$rf
        .getRequest('AuthRequest')
        .showPublicMdtRoomByName(name)
        .then(r => {
          self.mdt_room = r.data
          if (r.data.members) {
            self.user_member = r.data.members.find(
              d => d.user_id === self.user_id
            )
            self.room_members = r.data.members
            self.createLocalTracks()
            self.onConnectSocket()
          }
        })
        .catch(() => {
          this.backToList()
          this.$toast.open({
            message: 'Lấy thông tin phòng thất bại',
            type: 'error'
          })
        })
        .finally(() => {
          self.loading = false
        })
    },
    async onConnectSocket () {
      const self = this
      // Have this in case you stop running your laravel echo server
      if (this.$echo && this.$user) {
        const user = this.$user
        self.whisper_room = this.$echo.private('twl_room.' + self.room_name)
        self.whisper_room
          .listen('RoomMessageWasReceived', function (_data) {
            const { message } = _data
            const { type, data } = JSON.parse(message)
            console.log(type, data)
            if (type === 'refreshMdtMember') {
              self.onRefreshMembers(data)
            }
            if (type === 'refreshMdtCase') {
              self.onUserChangeCaseStatus(data)
            }
            if (type === 'refreshMdtDiseases') {
              self.onUserChangeCaseStatus(data)
            }
          })
          .listenForWhisper('joinRoom', e => {
            if (e.id !== user.id) {
              self.onUserJoinRoom(e)
            }
            self.whisper_room.whisper('userOnline', {
              id: user.id,
              name: user?.name
            })
            console.log('sendUserOnline', {
              id: user.id,
              name: user?.name
            })
            if (self.user_member?.role === 4) {
              self.whisper_room.whisper('changeSlideShow', {
                id: user.id,
                name: user?.name,
                role: self.user_member?.role,
                showing_id: self.showing_id
              })
              self.whisper_room.whisper('changeModePresent', {
                id: user.id,
                name: user?.name,
                role: self.user_member?.role,
                show_slide: self.show_slide
              })
              self.whisper_room.whisper('changeConcludingForm', {
                id: self.$user.id,
                name: self.$user?.name,
                role: self.user_member?.role,
                el_id: self.concluding_data.id,
                form: self.concluding_data.form
              })
              self.whisper_room.whisper('changeShowType', {
                id: self.$user.id,
                name: self.$user?.name,
                role: self.user_member?.role,
                type: self.show_type
              })
              self.whisper_room.whisper('changeOperationForm', {
                id: self.$user.id,
                name: self.$user?.name,
                role: self.user_member?.role,
                el_id: self.operation_data.id,
                form: self.operation_data.form
              })
            }
          })
          .listenForWhisper('userOnline', e => {
            if (e.id !== user.id) {
              self.onUserJoinRoom(e)
            }
            if (e.id === user.id) {
              // alert('Tài khoản của bạn đã tham gia cuộc gọi này ở thiết bị khác! Vui lòng kiểm tra và thử lại.')
            }
          })
          .listenForWhisper('quitRoom', e => {
            if (e.id !== user.id) {
              console.log('quitRoom', e)
              self.onUserQuitRoom(e)
            }
          })
          .listenForWhisper('changeSlideShow', e => {
            if (e.id !== user.id) {
              if (e.role === 4) {
                self.showing_id = e.showing_id
                self.indicator = e.name
              }
            }
          })
          .listenForWhisper('changeShowType', e => {
            if (e.id !== user.id) {
              if (e.role === 4) {
                self.show_type = e.type
                self.indicator = e.name
              }
            }
          })
          .listenForWhisper('changeConcludingForm', e => {
            if (e.id !== user.id) {
              if (e.role === 4) {
                self.concluding_data = {
                  form: e.form,
                  id: e.el_id
                }
                self.indicator = e.name
              }
            }
          })
          .listenForWhisper('changeOperationForm', e => {
            if (e.id !== user.id) {
              if (e.role === 4) {
                self.operation_data = {
                  form: e.form,
                  id: e.el_id
                }
                self.indicator = e.name
              }
            }
          })
      }
    },
    //
    async publicMdtCheckin (id) {
      const params = {
        public_mdt_id: id,
        status: 1
      }
      await this.$rf.getRequest('AuthRequest').publicMdtCheckin(params)
    },
    async getPublicMdtCheckinMembers (id) {
      const self = this
      const params = {
        date: this.moment().format('YYYY-MM-DD'),
        public_mdt_id: id,
        limit: 1000
      }
      self.$rf
        .getRequest('AuthRequest')
        .getPublicMdtCheckinMembers(params)
        .then(r => {
          self.checkin_members = r.data
        })
    },
    //
    //
    refreshCase () {
      this.getMdtCases(this.mdt_room.id)
    },
    async getMdtCases (id, isNext) {
      const self = this
      this.params_case.loading = true
      const page = 1
      // if (!isNext) {
      //   this.public_mdt_cases = []
      // } else {
      //   page = this.params_case.page + 1
      // }
      const params = {
        public_mdt_id: id,
        limit: 1000,
        sort: this.params_case.sort,
        sort_by: this.params_case.sort_by,
        page: page,
        date: this.moment().format('YYYY-MM-DD')
      }
      // if (this.params_case.status !== null) {
      //   params.status = this.params_case.status
      // }
      if (this.params_case.search) {
        params.search = this.params_case.search
      }

      await this.$rf
        .getRequest('AuthRequest')
        .getMdtCases(params)
        .then(r => {
          // if (r && r.data) {
          //   self.params_case.page = r.data.current_page
          //   self.public_mdt_cases.current_page = r.data.current_page
          //   if (!isNext) {
          //     self.public_mdt_cases = r.data
          //   } else {
          //     if (r.data?.count) {
          //       const old = self.public_mdt_cases.data
          //       const newObj = r.data.data
          //       self.public_mdt_cases.data = old.concat(newObj)
          //     }
          //   }
          // }
          self.public_mdt_cases = r.data

          if (this.$route.query.showing_id) {
            this.showing_id = parseInt(this.$route.query.showing_id)
          }
        })
        .finally(() => {
          self.params_case.loading = false
        })
    },
    onRefreshMembers (userInfo) {
      this.getPublicMdtCheckinMembers(this.mdt_room.id)
      this.showPublicMdtRoomByName(this.room_name)
    },
    onUserChangeCaseStatus (userInfo) {
      this.getMdtCases(this.mdt_room.id)
    },
    //
    onUserJoinRoom (userInfo) {
      console.log(userInfo?.name + ' joined', userInfo)
    },
    onUserQuitRoom (userInfo) {
      console.log(userInfo?.name + ' left', userInfo)
    },
    //
    onShowModalSpBr (show) {
      window.$('#modalSuportBrowser').modal(show ? 'show' : 'hide')
    },
    //
    hideInfo () {
      this.show_info = false
    },
    showInfo () {
      this.show_info = !this.show_info
      this.hideFunctions()
    },
    hideFunctions () {
      this.show_function = false
    },
    showFunctions () {
      this.show_function = !this.show_function
      this.hideInfo()
    },
    //
    micChangeStatus () {
      this.mic_status = !this.mic_status
    },
    camChangeStatus () {
      this.cam_status = !this.cam_status
    },
    micChangeStatusSlideShow () {
      this.micStatusSlideShow = !this.micStatusSlideShow
    },
    camChangeStatusSlideShow () {
      this.camStatusSlideShow = !this.camStatusSlideShow
    },
    screenChangeStatus () {
      this.screen_status = !this.screen_status
    },
    //
    showCaseDetail (id) {
      const user = this.$user
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = `https://dev.hodo.app/#/doctor/mdt-disease/${id}?token=${appUtils.getLocalToken()}`
      } else {
        dm = `${user?.domain ||
          'https://hodo.app'}/#/doctor/mdt-disease/${id}?token=${appUtils.getLocalToken()}`
      }
      window.open(dm, '_blank')
    },
    goToRoom () {
      const self = this
      self.getPublicMdtCheckinMembers(self.mdt_room.id)
      self.getMdtCases(self.mdt_room.id)
      self.publicMdtCheckin(self.mdt_room.id)
    },
    //
    createLocalTracks () {
      const self = this
      const params = {
        audio: false,
        video: false
      }
      if (self.has_video) {
        params.video = { width: 1280, height: 720 }
        self.cam_status = true
      }
      if (self.has_audio) {
        params.audio = true
        self.mic_status = true
      }
      navigator.mediaDevices
        .getUserMedia(params)
        .then(stream => {
          self.local_stream = stream
        })
        .catch(() => {
          self.cam_status = false
          self.mic_status = false
          self.has_audio = false
          self.has_video = false
        })
    },
    join () {
      this.goToRoom()
      if (this.local_stream) {
        this.local_stream.getTracks().forEach(t => t.stop())
      }
      this.local_stream = null
      this.step = 2
      const user = this.$user
      setTimeout(() => {
        this.whisper_room.whisper('joinRoom', {
          id: user.id,
          name: user?.name
        })
        console.log('sendJoinRoom', {
          id: user.id,
          name: user?.name
        })
      }, 1000)
      if (this.user_member?.role === 4) {
        this.whisper_room.whisper('changeSlideShow', {
          id: user.id,
          name: user?.name,
          role: this.user_member?.role,
          showing_id: this.showing_id
        })
        console.log('sendShangeSlideShow', {
          id: user.id,
          name: user?.name,
          role: this.user_member?.role,
          showing_id: this.showing_id
        })
      }
    },
    setShowingId (id) {
      const user = this.$user
      this.showing_id = id
      this.indicator = ''
      if (this.user_member?.role === 4) {
        this.whisper_room.whisper('changeSlideShow', {
          id: user.id,
          name: user?.name,
          role: this.user_member?.role,
          showing_id: id
        })
        // console.log('sendShangeSlideShow', {
        //   id: user.id,
        //   name: user?.name,
        //   role: this.user_member?.role,
        //   showing_id: id
        // })
      }
    },
    onDisconect () {
      setTimeout(() => {
        this.disconect++
      })
    },
    onShareScreen () {
      this.screenChangeStatus()
      this.step = 2
    },
    isFirefox () {
      var mediaSourceSupport = !!navigator.mediaDevices.getSupportedConstraints()
        .mediaSource
      var matchData = navigator.userAgent.match('Firefox')
      var firefoxVersion = 0
      if (matchData && matchData[1]) {
        firefoxVersion = parseInt(matchData[1], 10)
      }
      return mediaSourceSupport && firefoxVersion >= 52
    },
    isChrome () {
      return 'chrome' in window
    },
    canScreenShare () {
      return this.isFirefox() || this.isChrome()
    },
    //
    testSpk () {
      this.$refs.audioTest.play()
    },
    setChangingMic (stt) {
      this.changing_mic = stt
    },
    setChangingCam (stt) {
      this.changing_cam = stt
    },
    setSlideShow (status) {
      const user = this.$user
      this.showing_id = status
      this.indicator = ''
      // if (this.dr_member?.role === 4) {
      this.show_slide = status
      this.whisper_room.whisper('changeModePresent', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        show_slide: status
      })
      console.log('changeModePresent', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        show_slide: status
      })
      // } else {
      //   alert('Bạn không có quyền thay đổi mode trình chiếu')
      // }
    }
  },
  beforeDestroy () {
    this.onDisconect()
  }
}
</script>

<style scoped>
.town-hall-track {
  width: 100%;
  max-width: 700px;
}
.town-hall {
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  flex-wrap: wrap;
  margin: 0 auto;
}
.town-hall-info {
  width: 100%;
  max-width: 400px;
}
.h-100vh {
  height: 100vh;
}
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
}
.town-hall-info .btn {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  font-weight: 500;
}
.info-wraper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.info-wraper-content {
  padding: 16px;
  width: 546px;
  height: 100vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.info-wraper-backdrop {
  width: calc(100% - 546px);
  height: 100vh;
}

.disease-in-process-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.btn-header {
  width: 142px;
  max-width: 30vw;
}
.mdt-paticipant-wraper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.mdt-paticipant-backdrop {
  width: calc(100% - 259px);
  height: 100vh;
}
.mdt-paticipant-content {
  height: 100vh;
  width: 259px;
  padding: 16px;
  background: #fff;
}
.pe-none {
  pointer-events: none;
}
.main-content {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.main-content-item {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
