<template>
  <div class="h-100vh">
    <div v-if="loading" class="loading-meet-room">
      <img src="../../../public/assets/images/gif/Loading.gif" width="212" height="212" alt />
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'WebAppJoinPMdt',
  components: {},
  data () {
    return {
      mdt_room: null,
      members: [],
      loading: false,
      is_member: false,
      verified: false,
      login_otp: false,
      otp: ''
    }
  },
  created () {
    this.loading = true
  },
  mounted () {
    if (this.$route.query.token) {
      this.onLogin(this.$route.query.token)
    } else {
      this.$targetRoute = `/#/pmdt/${this.$route.params.name}`
      this.$router.push({ name: 'Login' })
    }
  },
  methods: {
    backToList () {
      this.$router.push({ name: 'Home' })
    },
    async showPublicMdtRoomByName () {
      const self = this
      await this.$rf
        .getRequest('AuthRequest')
        .showPublicMdtRoomByName(this.$route.params.name)
        .then(r => {
          self.mdt_room = r.data
          let path = `/#/pmdt/${self.$route.params.name}`
          if (this.$route.query.showing_id) {
            path += '?showing_id=' + this.$route.query.showing_id
          }
          window.location.href = path
        })
        .catch(e => {
          console.log(e)
          this.$toast.open({
            message: 'Phòng không tồn tại hoặc bạn không phải thành viên',
            type: 'warning'
            // all other options
          })
          this.$router.push({ name: 'Home' })
        })
    },
    async onLogin (token) {
      const self = this
      const name = self.$route.params.name
      if (token) {
        self.loading = true
        appUtils.setLocalToken(token)
        await self.$rf
          .getRequest('AuthRequest')
          .me()
          .then(resp => {
            if (resp) {
              appUtils.setLocalUser(resp.data)
              const lang = appUtils.getLangCode(resp.data.lang) || 'vi'
              this.$lang = lang
              appUtils.setLocalLang(lang)
              setTimeout(() => {
                self.showPublicMdtRoomByName()
              }, 50)
            }
          })
          .catch(e => {
            console.log(e)
            self.$toast.open({
              message: 'Token hết hạn vui lòng đăng nhập lại',
              type: 'warning'
              // all other options
            })
            appUtils.setLocalToken(null)
            self.$targetRoute = `/#/pmdt/${name}`
            setTimeout(() => {
              window.location.href = '/#/login'
            }, 100)
          })
      }
    }
  }
}
</script>

<style scoped>
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
}
.login-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  padding: 16px;
}
.login-form {
  width: 100%;
  max-width: 500px;
}
</style>
