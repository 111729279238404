<template>
  <div class="login-wraper">
    <div class="login-form border p-3 box-shadow radius-1r">
      <div>
        <div class="text-black mb-5">
          <p class="fs-32 fw-500">Chào mừng bạn đã đến với HODO Meet</p>
        </div>
        <div class="text-start" v-if="!login_otp">
          <div class="mb-3">
            <label class="fs-20 fw-500 text-black required mb-2">Email</label>
            <input
              type="text"
              class="form-control"
              required
              aria-describedby="emailHelp"
              placeholder="Hodo@905.vn"
              v-model="form.username"
            />
          </div>
          <div class="mb-3">
            <div class="row mb-2">
              <div class="col-sm-6">
                <label class="fs-20 fw-500 text-black required">Mật khẩu</label>
              </div>
            </div>
            <div class="input-group">
              <input
                type="password"
                required
                class="form-control"
                placeholder="Mật Khẩu..."
                v-model="form.password"
              />
              <!-- <span class="input-group-text bg-white border-0"><i class="fa fa-eye"></i></span> -->
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 d-flex justify-content-between align-items-center">
              <button
                type="button"
                class="btn btn-primary bg-pri bd-pri"
                :disabled="loading"
                @click="onLogin"
              >
                <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                Đăng Nhập
              </button>
              <div class="d-flex align-items-center">
                <p class="me-1 mb-0">Chưa có password?</p>
                <a href="javascript:;" class="txt-pri" @click="loginWOTP">Đăng nhập bằng OTP</a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="login_otp && !sendedOtp">
          <div class="text-black">
            <p class="fs-24 fw-500">Nhập email của bạn</p>
          </div>
          <div class="text-start">
            <div class="mb-3">
              <input
                type="email"
                class="form-control"
                required
                v-model="form_otp.email"
                @keyup.enter="onSendOtp"
              />
            </div>
            <div class="row mt-4">
              <div class="col-12 d-flex justify-content-between">
                <button
                  type="button"
                  class="btn btn-primary bg-pri bd-pri"
                  :disabled="loading"
                  @click="onSendOtp"
                >
                  <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                  Gửi OTP
                </button>
                <a
                  href="javascript:;"
                  @click="
                    () => {
                      login_otp = false;
                      sendedOtp = false;
                    }
                  "
                >Đăng nhập bằng mật khẩu</a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loading && login_otp && sendedOtp">
          <div class="text-black mb-5">
            <p class="fs-24 fw-500">Một mã OTP đã được gửi tới email của bạn, vui lòng nhập mã OTP</p>
          </div>
          <div class="text-start">
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                required
                @keyup.enter="onLoginOTP"
                v-model="form_otp.otp"
              />
            </div>
            <div class="row mt-4">
              <div class="col-12 d-flex justify-content-between">
                <button
                  type="button"
                  class="btn btn-primary bg-pri bd-pri"
                  :disabled="loading"
                  @click="onLoginOTP"
                >
                  <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                  Đăng nhập
                </button>
                <a
                  href="javascript:;"
                  @click="
                    () => {
                      login_otp = false;
                      sendedOtp = false;
                    }
                  "
                >Đăng nhập bằng mật khẩu</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      form_otp: {
        email: '',
        otp: ''
      },
      loading: false,
      login_otp: false,
      sendedOtp: false
    }
  },
  mounted () {
    if (this.$user) {
      this.$router.push({ path: '/' })
    }
  },
  methods: {
    async onLogin () {
      const self = this
      try {
        self.loading = true
        let resp = await self.$rf.getRequest('AuthRequest').login(self.form)
        if (resp.data.access_token) {
          appUtils.setLocalToken(resp.data.access_token)

          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            appUtils.setLocalUser(resp.data)
            const lang = appUtils.getLangCode(resp.data.lang) || 'vi'
            this.$lang = lang
            appUtils.setLocalLang(lang)
          }

          self.loading = false

          setTimeout(() => {
            let url = '/'
            if (self.$targetRoute) {
              url = self.$targetRoute
              self.$targetRoute = ''
            }
            window.location.href = url
          }, 300)
        }
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async onSendOtp () {
      const self = this
      if (!this.form_otp.email) {
        alert('Bạn chưa nhập email')
        return
      }
      if (!this.validateEmail(this.form_otp.email)) {
        alert('Vui lòng nhập đúng định dạng email')
        return
      }
      const params = {
        email: this.form_otp.email
      }
      await this.$rf
        .getRequest('AuthRequest')
        .sendOtpEmail(params)
        .then(r => {
          self.sendedOtp = true
        })
    },
    async onLoginOTP () {
      const self = this
      const params = {
        email: this.form_otp.email,
        otp: this.form_otp.otp
      }
      try {
        let resp = await this.$rf.getRequest('AuthRequest').loginOtp(params)
        if (resp.data.access_token) {
          appUtils.setLocalToken(resp.data.access_token)

          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            appUtils.setLocalUser(resp.data)
            const lang = appUtils.getLangCode(resp.data.lang) || 'vi'
            this.$lang = lang
            appUtils.setLocalLang(lang)
          }

          self.loading = false

          setTimeout(() => {
            let url = '/'
            if (self.$targetRoute) {
              url = self.$targetRoute
              self.$targetRoute = ''
            }
            window.location.href = url
          }, 300)
        }
      } catch (e) {
        self.$toast.open({
          message: 'Sai OTP',
          type: 'warning'
          // all other options
        })
        console.log(e)
      }
    },
    loginWOTP () {
      this.form_otp.email = this.form.username
      this.login_otp = true
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  }
}
</script>

<style scoped>
.login-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  padding: 16px;
}
.login-form {
  width: 100%;
  max-width: 500px;
  height: 450px;
}
</style>
