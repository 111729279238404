<template>
  <div class="vh-full d-flex flex-column" :class="step === 2 ? 'bg-252A30' : ''">
    <Navbar v-if="step === 1" />

    <div v-if="loading" class="loading-meet-room">
      <img src="../../../public/assets/images/gif/Loading.gif" width="212" height="212" alt />
    </div>

    <div class="d-flex flex-column flex-grow-1">
      <div
        class="d-flex flex-column justify-content-center flex-grow-1"
        v-if="step === 1 && !loading"
      >
        <div class="town-hall container">
          <div class="row align-items-center">
            <div class="col-6 town-hall-track">
              <PrepareTrackX :roomName="$route.params.name" />
            </div>
            <div class="col-6 town-hall-info text-center" v-if="meet_room">
              <p
                class="text-center fs-24 fw-500 mb-1"
                :class="!sameDate(meet_room.start_time) ? 'mb-4' : ''"
              >{{ meet_room.name }}</p>
              <p
                class="text-center fs-18"
                v-show="sameDate(meet_room.start_time)"
              >{{ timeUtils.getTimeStringDetail(meet_room) }}</p>

              <template v-if="is_member">
                <button
                  class="btn btn-primary bg-pri bd-pri me-2"
                  @click="handleJoin"
                >{{$t('meet_room.btn_join')}}</button>
                <button class="btn btn-light txt-pri box-shadow">Trình bày</button>
              </template>
              <button
                class="btn btn-primary bg-pri bd-pri"
                v-else
                disabled
              >Bạn không thể tham gia cuộc họp này</button>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column flex-grow-1" v-if="step === 2 && !loading">
        <HoDoConferenceX />
      </div>

      <div class="info-wraper box-shadow" v-show="show_info">
        <div class="info-wraper-backdrop" @click="show_info ^= show_info"></div>
        <div class="info-wraper-content">
          <MeetInfo :meet_room="meet_room" :room_members="members" />
        </div>
      </div>
      <div class="info-wraper box-shadow" v-show="show_function">
        <div class="info-wraper-backdrop" @click="show_function ^= show_function"></div>
        <div class="info-wraper-content">
          <MeetAttachments
            :meet_room="meet_room"
            :rf_concluding="rf_concluding"
            :rf_attach="rf_attach"
            :room_members="members"
          />
        </div>
      </div>
      <div class="audio d-none">
        <audio ref="audioTest" id="spk-test" preload="auto">
          <source src="../../../public/assets/audio/test_spk.mp3" type="audio/mp3" />
        </audio>
      </div>
      <SupportBrowser />
    </div>
  </div>
</template>

<style lang="css" scoped>
.bg-252A30 {
  background-color: #252a30;
}
</style>

<script>
import PrepareTrackX from '../../components/VideoCore/PrepareTrackX.vue'
import MeetInfo from '../../components/meets/MeetInfo.vue'
import MeetAttachments from '../../components/meets/MeetAttachments.vue'
import HoDoConferenceX from '../../components/VideoCore/HoDoConferenceX.vue'
import Navbar from '../../components/layouts/Navbar.vue'
import timeUtils from '../../utils/timeUtils'
import SupportBrowser from '../../components/PopupNotification/SupportBrowser.vue'
import appUtils from '../../utils/appUtils'

export default {
  name: 'MeetRoomX',
  components: {
    PrepareTrackX,
    HoDoConferenceX,
    Navbar,
    MeetInfo,
    MeetAttachments,
    SupportBrowser
  },
  inject: ['videoProvide', 'connect'],
  data () {
    return {
      step: 1,
      meet_room: null,
      members: [],
      is_support_browser: false,
      is_member: false,
      loading: false,
      screenStatus: false,
      show_info: false,
      show_function: false,
      timeUtils,
      whisperRoom: null,
      rf_concluding: 0,
      rf_attach: 0,
      localTracks: this.videoProvide.localTracks || [],
      isAcquiringLocalTracks: this.videoProvide.isAcquiringLocalTracks || false
    }
  },
  async created () {
    this.loading = true
    this.room_name = this.$route.params.name

    await this.showMeetRoomByName()
    await this.getMeetRoomMembers()
  },
  async mounted () {
    const self = this
    const devices = await appUtils.getDeviceInfo()

    if (!self.$user) {
      self.$targetRoute = `/#/meet/${self.$route.params.name}`
      self.$router.push({ name: 'Login' })
      return false
    }

    self.$nextTick(function () {
      window.$('#modalSuportBrowser').on('hidden.bs.modal', function (e) {
        self.is_support_browser = false
        self.backToList()
      })
    })

    if (!devices) {
      self.onShowModalSpBr(true)
    } else {
      self.is_support_browser = true

      if (
        !navigator.userAgent.search('Safari') >= 0 ||
        !navigator.userAgent.search('Chrome') < 0
      ) {
        self.onConnectSocket()
      }
    }
  },
  watch: {
    videoProvide: {
      handler (data) {
        // console.log(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    backToList () {
      const user = this.$user
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.hodo.app/#/hoi-chan'
      } else {
        dm = `${user?.domain || 'https://hodo.app'}/#/hoi-chan`
      }
      window.location.href = dm
    },
    async showMeetRoomByName () {
      const self = this
      const user = this.$user
      await this.$rf
        .getRequest('AuthRequest')
        .showMeetRoomByName(this.$route.params.name)
        .then(r => {
          self.meet_room = r.data
          self.members = r.data.members
          self.is_member = r?.data?.members?.find(m => m.user_id === user.id)
        })
        .catch(() => {
          this.$toast.open({
            message: 'Không tìm thấy thông tin phòng',
            type: 'warning'
          })
          this.$router.push({ name: 'Home' })
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 300)
        })
    },
    async getMeetRoomMembers () {
      const self = this
      if (!this.meet_room) return
      const params = {
        date: this.moment().format('DD-MM-YYYY'),
        limit: 1000
      }
      await this.$rf
        .getRequest('AuthRequest')
        .getMeetRoomMembers(this.meet_room.id, params)
        .then(r => {
          self.members = r.data
        })
    },
    onRefreshMembers () {
      this.getMeetRoomMembers()
    },
    async onConnectSocket () {
      const self = this
      // Have this in case you stop running your laravel echo server
      if (self.$echo) {
        const user = self.$user
        self.whisperRoom = self.$echo.private(`twl_room.${self.room_name}`)
        self.whisperRoom
          .listen('RoomMessageWasReceived', function (_data) {
            const { message } = _data
            const { type, data } = JSON.parse(message)
            console.log(type, data)
            if (type === 'refreshMeetMember') {
              console.log(data)
              self.onRefreshMembers(data)
            }
            if (type === 'refreshMeetAttachment') {
              self.rf_attach++
              console.log('refreshing Attachment')
            }
            if (type === 'refreshMeetConcluding') {
              self.rf_concluding++
              console.log('refreshing Concluding')
            }
          })
          .listenForWhisper('joinRoom', e => {
            if (e.id !== user.id) {
              console.log('joinRoom', e)
              self.onRefreshMembers()
            }
            self.whisperRoom.whisper('userOnline', {
              id: user.id,
              name: user?.doctor?.name
            })
            console.log('sendUserOnline', {
              id: user.id,
              name: user?.doctor?.name
            })
          })
          .listenForWhisper('userOnline', e => {
            if (e.id !== user.id) {
              console.log('userOnline', e.id)
            }
            if (e.id === user.id) {
              // alert('Tài khoản của bạn đã tham gia cuộc gọi này ở thiết bị khác! Vui lòng kiểm tra và thử lại.')
            }
          })
          .listenForWhisper('quitRoom', e => {
            if (e.id !== user.id) {
              console.log('quitRoom', e)
            }
          })

        setTimeout(() => {
          self.whisperRoom.whisper('joinRoom', {
            id: user.id,
            name: user?.doctor?.name
          })
          console.log('sendJoinRoom', {
            id: user.id,
            name: user?.doctor?.name
          })
        }, 1000)
      }
    },
    async handleJoin () {
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .getTwilioToken()
        .then(r => {
          if (r && r.data && r.data.token) {
            const token = r.data.token
            self.connect(token, {
              name: self.room_name
            })
          }
        })
        .then(() => {
          this.step = 2
        })
    },
    sameDate (start) {
      const startTime = window.moment(start)
      const timeCheck = window.moment('00:00:00')
      return startTime.isSame(timeCheck, 'day')
    }
  },
  destroyed () {
    if (this.$echo) this.$echo.leave('twl_room.' + this.room_name)
  }
}
</script>
