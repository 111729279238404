<template>
  <div class="h-100vh" :class="step == 2 ? 'bg-252A30' : ''">
    <Navbar v-if="step == 1" />
    <div v-if="step == 1 && !loading">
      <div class="town-hall">
        <div class="town-hall-track">
          <MeetPrepareTrack
            :localTracks="localTracks"
            @testSpk="testSpk"
            @toggleVideoEnabled="toggleVideoEnabled"
            @toggleAudioEnabled="toggleAudioEnabled"
            @resetIsTrackEnabled="resetIsTrackEnabled"
          />
        </div>
        <div class="town-hall-info text-center" v-if="meet_room">
          <p
            class="text-center fs-24 fw-500 mb-1"
            :class="!sameDate(meet_room.start_time) ? 'mb-4' : ''"
          >{{ meet_room.name }}</p>
          <p
            class="text-center fs-18"
            v-show="sameDate(meet_room.start_time)"
          >{{ timeUtils.getTimeStringDetail(meet_room) }}</p>
          <!-- <p class="text-center fs-16 fw-500">
            Không có người nào khác ở đây
            <span class="fs-12 fw-400">(chức năng đang phát triển)</span>
          </p>-->
          <div v-if="isLoadingVideo && !isEnabledVideo" class="text-center">
            <span>{{ $t('lbl_loading') }}...</span>
          </div>
          <template v-else>
            <button
              class="btn btn-primary bg-pri bd-pri me-2"
              v-if="is_member"
              @click="handleJoin()"
            >{{$t('meet_room.btn_join')}}</button>
            <button
              class="btn btn-primary bg-pri bd-pri"
              v-if="!is_member"
              disabled
            >{{ $t('meet_room.lbl_warn_cannot_join') }}</button>
            <button
              v-if="is_member"
              class="btn btn-light txt-pri box-shadow"
              @click="onShareScreen()"
              :disabled="!canScreenShare()"
            >{{ $t('meet_room.btn_present') }}</button>
          </template>
        </div>
      </div>
    </div>
    <div v-if="step == 2 && !loading">
      <HoDoConference
        :hasAudio="hasAudio"
        :hasVideo="hasVideo"
        :isConnecting="isConnecting"
        :room="room"
        :room_info="meet_room"
        :room_name="$route.params.name"
        :room_members="members"
        :show_info="show_info"
        :show_function="show_function"
        @showInfo="showInfo"
        @showFunctions="showFunctions"
        @disconected="backToList"
        @setStep="setStep"
      />
    </div>
    <div v-if="loading" class="loading-meet-room">
      <img src="../../../public/assets/images/gif/Loading.gif" width="212" height="212" alt />
    </div>
    <div class="info-wraper box-shadow" v-show="show_info">
      <div class="info-wraper-backdrop" @click="show_info ^= show_info"></div>
      <div class="info-wraper-content">
        <MeetInfo :meet_room="meet_room" :room_members="members" @hideInfo="hideInfo" />
      </div>
    </div>
    <div class="info-wraper box-shadow" v-show="show_function">
      <div class="info-wraper-backdrop" @click="show_function ^= show_function"></div>
      <div class="info-wraper-content">
        <MeetAttachments
          :meet_room="meet_room"
          :rf_concluding="rf_concluding"
          :rf_attach="rf_attach"
          @hideFunctions="hideFunctions"
          :room_members="members"
        />
      </div>
    </div>
    <div class="audio d-none">
      <audio ref="audioTest" id="spk-test" preload="auto">
        <source src="../../../public/assets/audio/test_spk.mp3" type="audio/mp3" />
      </audio>
    </div>
    <SupportBrowser />
    <ModalAddUsername @onClose="handleAddUsernameSuccess" />
  </div>
</template>

<script>
import MeetPrepareTrack from '../../components/meets/MeetPrepareTrack.vue'
import MeetInfo from '../../components/meets/MeetInfo.vue'
import MeetAttachments from '../../components/meets/MeetAttachments.vue'
import HoDoConference from '../../components/TwilioComponent/HoDoConference.vue'
import Navbar from '../../components/layouts/Navbar.vue'
import timeUtils from '../../utils/timeUtils'
import SupportBrowser from '../../components/PopupNotification/SupportBrowser.vue'
import ModalAddUsername from './ModalAddUsername.vue'

import Video from 'twilio-video'
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  // SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY
} from '../../utils/constants'
import appUtils from '../../utils/appUtils'
import { useConnectionOptions } from '../../mixins/VideoCore/useConnectionOptions'
import { mapActions, mapGetters } from 'vuex'

const noiseCancellationOptions = {
  sdkAssetsPath: '/noisecancellation',
  vendor: 'krisp'
}

export default {
  name: 'MeetRoom',
  components: {
    MeetPrepareTrack,
    HoDoConference,
    Navbar,
    MeetInfo,
    MeetAttachments,
    SupportBrowser,
    ModalAddUsername
  },
  mixins: [useConnectionOptions],
  data () {
    return {
      step: 1,
      meet_room: null,
      members: [],
      local_stream: null,
      has_devices: [],
      hasVideo: false,
      hasAudio: false,
      // micStatus: false,
      // camStatus: false,
      is_support_browser: false,
      is_member: false,
      loading: false,
      // screenStatus: false,
      show_info: false,
      show_function: false,
      timeUtils,
      whisperRoom: null,
      rf_concluding: 0,
      rf_attach: 0,
      audioTrack: null,
      videoTrack: null,
      isAcquiringLocalTracks: false,
      isKrispEnabled: false,
      isKrispInstalled: false,
      room: null,
      isConnecting: false,
      isPublishing: false,
      isLoadingVideo: true
    }
  },
  created () {
    this.loading = true
    this.room_name = this.$route.params.name
  },
  async mounted () {
    await this.preloadData()
  },
  computed: {
    ...mapGetters(['camStatus', 'micStatus', 'screenStatus']),
    localTracks () {
      return [this.audioTrack, this.videoTrack].filter(
        track => typeof track !== 'undefined' && track !== null
      )
    },
    isEnabledVideo () {
      this.$store.commit('CAM_STATUS_CHANGE', !!this.videoTrack?.isEnabled)
      return this.videoTrack?.isEnabled || false
    }
  },
  watch: {
    localTracks: {
      handler (data) {
        this.setVideoTrack(data)
      },
      deep: true
    },
    audioTrack: {
      handler (data) {
        this.getIsTrackEnabled(data)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['toggleCamStatus', 'toggleMicStatus', 'toggleScreenStatus']),
    async preloadData () {
      const self = this
      const devices = await appUtils.getDeviceInfo()

      if (!this.$user) {
        this.$targetRoute = `/#/meet/${this.$route.params.name}`
        this.$router.push({ name: 'Login' })
        return false
      }
      this.$nextTick(function () {
        window.$('#modalSuportBrowser').on('hidden.bs.modal', function (e) {
          self.is_support_browser = false
          self.backToList()
        })
      })

      if (!devices) {
        self.onShowModalSpBr(true)
      } else {
        self.is_support_browser = true

        if (
          !navigator.userAgent.search('Safari') >= 0 ||
          !navigator.userAgent.search('Chrome') < 0
        ) {
          await self.showMeetRoomByName()
          await self.onConnectSocket()
          await self.getAudioAndVideoTracks()
        }
      }

      setTimeout(() => {
        this.isLoadingVideo = false
      }, 5000)
    },
    setVideoTrack (data) {
      const localTracksData = data || this.localTracks || []
      this.videoTrack = localTracksData.find(
        track => !track.name.includes('screen') && track.kind === 'video'
      )
    },
    backToList () {
      const user = this.$user
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.hodo.app/#/meeting'
      } else {
        dm = `${user?.domain || 'https://hodo.app'}/#/meeting`
      }
      window.location.href = dm
    },
    async showMeetRoomByName () {
      const self = this
      const user = this.$user
      await this.$rf
        .getRequest('AuthRequest')
        .showMeetRoomByName(this.$route.params.name)
        .then(r => {
          self.meet_room = r.data
          self.members = r.data.members
          self.is_member = r?.data?.members?.find(m => m.user_id === user.id)
        })
        .catch(() => {
          this.$toast.open({
            message: 'Không tìm thấy thông tin phòng',
            type: 'warning'
          })
          this.$router.push({ name: 'Home' })
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 300)
        })
    },
    async getMeetRoomMembers () {
      const self = this
      if (!this.meet_room) return
      const params = {
        date: this.moment().format('DD-MM-YYYY'),
        limit: 1000
      }
      await this.$rf
        .getRequest('AuthRequest')
        .getMeetRoomMembers(this.meet_room.id, params)
        .then(r => {
          self.members = r.data
        })
    },
    setNoiseCancellation (enable) {
      const { noiseCancellation } = this.audioTrack
      if (noiseCancellation) {
        if (enable) {
          // If enabled, then the LocalAudioTrack will use the Krisp noise
          // cancellation instead of the browser's noise suppression.
          noiseCancellation.enable()
          this.isKrispEnabled = true
          this.isKrispInstalled = true
        } else {
          // If disabled, then the LocalAudioTrack will use the browser's
          // noise suppression instead of the Krisp noise cancellation.
          noiseCancellation.disable()
          this.isKrispEnabled = false
          this.isKrispInstalled = false
        }
      }
    },
    async getAudioAndVideoTracks () {
      const {
        // audioInputDevices,
        videoInputDevices,
        hasAudioInputDevices,
        hasVideoInputDevices
      } = await appUtils.getDeviceInfo()

      if (!hasAudioInputDevices && !hasVideoInputDevices) {
        this.toggleMicStatus(false)
        this.toggleCamStatus(false)
        this.hasAudio = false
        this.hasVideo = false

        return Promise.resolve()
      }

      if (this.isAcquiringLocalTracks || this.audioTrack || this.videoTrack) {
        this.toggleMicStatus(false)
        this.toggleCamStatus(false)

        return Promise.resolve()
      }

      this.isAcquiringLocalTracks = true

      // const selectedAudioDeviceId = window.localStorage.getItem(
      //   SELECTED_AUDIO_INPUT_KEY
      // )
      const selectedVideoDeviceId = window.localStorage.getItem(
        SELECTED_VIDEO_INPUT_KEY
      )
      // const hasSelectedAudioDevice = audioInputDevices.some(
      //   device =>
      //     selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
      // )
      const hasSelectedVideoDevice = videoInputDevices.some(
        device =>
          selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
      )

      // In Chrome, it is possible to deny permissions to only audio or only video.
      // If that has happened, then we don't want to attempt to acquire the device.
      const isCameraPermissionDenied = await appUtils.isPermissionDenied(
        'camera'
      )
      const isMicrophonePermissionDenied = await appUtils.isPermissionDenied(
        'microphone'
      )
      const shouldAcquireVideo =
        hasVideoInputDevices && !isCameraPermissionDenied
      const shouldAcquireAudio = !isMicrophonePermissionDenied
      const localTrackConstraints = {
        video: shouldAcquireVideo && {
          ...DEFAULT_VIDEO_CONSTRAINTS,
          name: `camera-${Date.now()}`,
          ...(hasSelectedVideoDevice &&
            selectedVideoDeviceId && {
            deviceId: { exact: selectedVideoDeviceId }
          })
        },
        audio: shouldAcquireAudio && {
          noiseCancellationOptions,
          name: `microphone-${Date.now()}`
        }
      }

      this.toggleMicStatus(true)
      this.toggleCamStatus(true)
      this.hasAudio = true
      this.hasVideo = true

      return Video.createLocalTracks(localTrackConstraints)
        .then(tracks => {
          const newVideoTrack = tracks.find(track => track.kind === 'video')
          const newAudioTrack = tracks.find(track => track.kind === 'audio')

          if (newVideoTrack) {
            this.videoTrack = newVideoTrack
            // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
            // in cases where the user's video is disabled.
            window.localStorage.setItem(
              SELECTED_VIDEO_INPUT_KEY,
              newVideoTrack.mediaStreamTrack.getSettings().deviceId || ''
            )
          }
          if (newAudioTrack) {
            this.audioTrack = newAudioTrack
            if (newAudioTrack.noiseCancellation) {
              this.setNoiseCancellation(true)
            }
          }
          // These custom errors will be picked up by the MediaErrorSnackbar component.
          if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
            const error = new Error()
            error.name = 'NotAllowedError'
            throw error
          }
          if (isCameraPermissionDenied) {
            throw new Error('CameraPermissionsDenied')
          }
          if (isMicrophonePermissionDenied) {
            throw new Error('MicrophonePermissionsDenied')
          }
        })
        .finally(() => (this.isAcquiringLocalTracks = false))
    },
    async getLocalVideoTrack () {
      const selectedVideoDeviceId = window.localStorage.getItem(
        SELECTED_VIDEO_INPUT_KEY
      )

      const { videoInputDevices } = await appUtils.getDeviceInfo()

      const hasSelectedVideoDevice = videoInputDevices.some(
        device =>
          selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
      )

      const options = {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice &&
          selectedVideoDeviceId && {
          deviceId: { exact: selectedVideoDeviceId }
        })
      }

      // eslint-disable-next-line no-return-await
      return await Video.createLocalVideoTrack(options).then(newTrack => {
        this.videoTrack = newTrack
        return newTrack
      })
    },
    async toggleVideoEnabled () {
      if (!this.isPublishing) {
        const string =
          'Camera ' +
          (this.camStatus
            ? this.$t('meet_room.device_sts_on')
            : this.$t('meet_room.device_sts_off'))
        this.$toast.open({
          message: string,
          type: this.camStatus ? 'warning' : 'success'
        })

        // this.camChangeStatus()
        this.toggleCamStatus(!this.$store.state.meetSettings.camStatus)

        if (this.videoTrack) {
          const localTrackPublication = this.localParticipant?.unpublishTrack(
            this.videoTrack
          )
          // eslint-disable-next-line no-unused-expressions
          this.localParticipant?.emit(
            'trackUnpublished',
            localTrackPublication
          )
          this.removeLocalVideoTrack()
          this.videoTrack = null
        } else {
          this.isPublishing = true
          await this.getLocalVideoTrack()
            .then(track => {
              // eslint-disable-next-line no-unused-expressions
              this.localParticipant?.publishTrack(track, { priority: 'high' })
              // console.log(this.localParticipant, track)
              this.videoTrack = track
            })
            .catch(error => console.log(error))
            .finally(() => {
              this.isPublishing = false
            })
        }
      }
    },
    toggleAudioEnabled () {
      if (this.audioTrack) {
        const string =
          'Micro ' +
          (this.micStatus
            ? this.$t('meet_room.device_sts_on')
            : this.$t('meet_room.device_sts_off'))
        this.$toast.open({
          message: string,
          type: this.micStatus ? 'warning' : 'success'
        })
        this.toggleMicStatus(!this.audioTrack?.isEnabled)
        this.audioTrack.isEnabled
          ? this.audioTrack.disable()
          : this.audioTrack.enable()

        this.getIsTrackEnabled(this.audioTrack)
      }
    },
    getIsTrackEnabled (track) {
      if (!track) return

      const setEnabled = () => this.toggleMicStatus(true)
      const setDisabled = () => this.toggleMicStatus(false)

      this.toggleMicStatus(track?.isEnabled)
      track.on('enabled', setEnabled)
      track.on('disabled', setDisabled)
    },
    resetIsTrackEnabled (track) {
      if (track) return
      const setEnabled = () => this.toggleMicStatus(true)
      const setDisabled = () => this.toggleMicStatus(false)
      track.off('enabled', setEnabled)
      track.off('disabled', setDisabled)
    },
    // removeLocalAudioTrack () {
    //   if (this.audioTrack) {
    //     this.audioTrack.stop()
    //     this.audioTrack = undefined
    //   }
    // },
    removeLocalVideoTrack () {
      if (this.videoTrack) {
        this.videoTrack.stop()
        this.videoTrack = undefined
      }
    },
    // micChangeStatus () {
    //   this.micStatus = !this.micStatus
    // },
    // camChangeStatus () {
    //   this.camStatus = !this.camStatus
    // },
    // screenChangeStatus () {
    //   this.screenStatus = !this.screenStatus
    // },
    onShareScreen () {
      this.toggleScreenStatus(!this.screenStatus)
      this.handleJoin()
    },
    isFirefox () {
      var mediaSourceSupport = !!navigator.mediaDevices.getSupportedConstraints()
        .mediaSource
      var matchData = navigator.userAgent.match('Firefox')
      var firefoxVersion = 0
      if (matchData && matchData[1]) {
        firefoxVersion = parseInt(matchData[1], 10)
      }
      return mediaSourceSupport && firefoxVersion >= 52
    },
    isChrome () {
      return 'chrome' in window
    },
    canScreenShare () {
      return this.isFirefox() || this.isChrome()
    },
    hideInfo () {
      this.show_info = false
    },
    showInfo () {
      this.show_info = !this.show_info
      this.hideFunctions()
    },
    hideFunctions () {
      this.show_function = false
    },
    showFunctions () {
      this.show_function = !this.show_function
      this.hideInfo()
    },
    onRefreshMembers () {
      this.getMeetRoomMembers()
    },
    async onConnectSocket () {
      const self = this
      // Have this in case you stop running your laravel echo server
      if (this.$echo) {
        const user = this.$user
        self.whisperRoom = this.$echo.private(`twl_room.${this.room_name}`)
        self.whisperRoom
          .listen('RoomMessageWasReceived', function (_data) {
            const { message } = _data
            const { type, data } = JSON.parse(message)
            // console.log(type, data)
            if (type === 'refreshMeetMember') {
              self.onRefreshMembers(data)
            }
            if (type === 'refreshMeetAttachment') {
              self.rf_attach++
              // console.log('refreshing Attachment')
            }
            if (type === 'refreshMeetConcluding') {
              self.rf_concluding++
              // console.log('refreshing Concluding')
            }
          })
          .listenForWhisper('joinRoom', e => {
            if (e.id !== user.id) {
              // console.log('joinRoom', e)
              self.onRefreshMembers()
            }
            self.whisperRoom.whisper('userOnline', {
              id: user.id,
              name: user?.doctor?.name
            })
            // console.log('sendUserOnline', {
            //   id: user.id,
            //   name: user?.doctor?.name
            // })
          })
          .listenForWhisper('userOnline', e => {
            if (e.id !== user.id) {
              // console.log('userOnline', e.id)
            }
            if (e.id === user.id) {
              // alert('Tài khoản của bạn đã tham gia cuộc gọi này ở thiết bị khác! Vui lòng kiểm tra và thử lại.')
            }
          })
          .listenForWhisper('quitRoom', e => {
            if (e.id !== user.id) {
              // console.log('quitRoom', e)
            }
          })

        setTimeout(() => {
          self.whisperRoom.whisper('joinRoom', {
            id: user.id,
            name: user?.doctor?.name
          })
          // console.log('sendJoinRoom', {
          //   id: user.id,
          //   name: user?.doctor?.name
          // })
        }, 1000)
      }
    },
    testSpk () {
      this.$refs.audioTest.play()
    },
    onShowModalSpBr (show) {
      window.$('#modalSuportBrowser').modal(show ? 'show' : 'hide')
    },
    async handleJoin () {
      if (!this.$user.name) {
        this.handleShowModalAddUsername(true)
        return
      }

      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .getTwilioToken()
        .then(r => {
          if (r && r.data && r.data.token) {
            const token = r.data.token
            self.room = self.connect(token, {
              ...this.connectionOptions,
              name: self.room_name,
              tracks: self.localTracks
            })
          }
        })
        .then(() => {
          this.setStep(2)
        })
    },
    sameDate (start) {
      const startTime = window.moment(start)
      const timeCheck = window.moment('00:00:00')
      return startTime.isSame(timeCheck, 'day')
    },
    disconnectLocalTracks () {
      // eslint-disable-next-line no-unused-expressions
      this.videoTrack?.stop()
      this.videoTrack = null

      // eslint-disable-next-line no-unused-expressions
      this.audioTrack?.stop()
      this.audioTrack = null
    },
    async connect (token, options) {
      this.isConnecting = true
      return Video.connect(token, {
        ...options
      })
        .then(newRoom => {
          this.room = newRoom

          this.room.localParticipant.setNetworkQualityConfiguration({
            local: 3,
            remote: 3
          })

          const disconnect = () => this.room.disconnect()

          // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          this.room.setMaxListeners(16)

          this.room.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => (this.room = null))
            window.removeEventListener('beforeunload', disconnect)

            if (appUtils.isMobile) {
              window.removeEventListener('pagehide', disconnect)
            }
          })

          // @ts-ignore
          window.twilioRoom = this.room

          this.room.localParticipant.videoTracks.forEach(publication =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('high')
          )

          this.isConnecting = false

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect)

          if (appUtils.isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect)
          }
        })
        .catch(error => {
          console.log(`ERROR: ${error.message}`, error)
          this.isConnecting = false
        })
    },
    handleShowModalAddUsername (show) {
      window.$('#modalAddUsername').modal(show ? 'show' : 'hide')
    },
    handleAddUsernameSuccess () {
      this.handleShowModalAddUsername(false)
      this.handleJoin()
    },
    setStep (data) {
      const value = typeof data === 'number' ? data : data.step
      const isRefresh = typeof data === 'object' ? data.isRefresh : false
      this.step = value
      if (isRefresh) window.location.reload()
    }
  },
  beforeDestroy () {
    this.disconnectLocalTracks()
  },
  destroyed () {
    if (this.$echo) this.$echo.leave('twl_room.' + this.room_name)
  }
}
</script>

<style scoped>
.town-hall-track {
  width: 100%;
  max-width: 700px;
}
.town-hall {
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  flex-wrap: wrap;
  margin: 0 auto;
}
.town-hall-info {
  width: 100%;
  max-width: 400px;
}
.bg-252A30 {
  background-color: #252a30 !important;
}
.h-100vh {
  height: 100vh;
}
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
}
.town-hall-info .btn {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  font-weight: 500;
}
.info-wraper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.info-wraper-content {
  padding: 16px;
  width: 546px;
  height: 100vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.info-wraper-backdrop {
  width: calc(100% - 546px);
  height: 100vh;
}
</style>
