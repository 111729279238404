<template>
  <div class="h-100vh">
    <div v-if="loading" class="loading-meet-room">
      <img src="../../../public/assets/images/gif/Loading.gif" width="212" height="212" alt />
    </div>
    <div v-if="!loading && login_otp">
      <div class="login-wraper">
        <form @submit.prevent="onLogin" class="login-form border p-3 box-shadow radius-1r">
          <div>
            <div class="text-black mb-5">
              <p class="fs-24 fw-500">Một mã OTP đã được gửi tới email của bạn, vui lòng nhập mã OTP</p>
            </div>
            <div class="text-start">
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  required
                  aria-describedby="emailHelp"
                  v-model="otp"
                />
              </div>
              <div class="row mt-4">
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary bg-pri bd-pri" :disabled="loading">
                    <span
                      class="spinner-border spinner-border-sm me-2"
                      role="status"
                      v-if="loading"
                    ></span>
                    Gửi
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'MagicLinkJoin',
  components: {},
  data () {
    return {
      meet_room: null,
      members: [],
      loading: false,
      is_member: false,
      verified: false,
      login_otp: false,
      otp: ''
    }
  },
  created () {
    this.loading = true
  },
  mounted () {
    this.preparing()
  },
  methods: {
    backToList () {
      this.$router.push({ name: 'Home' })
    },
    async preparing () {
      try {
        this.loading = true
        // await this.showMeetRoomByName()
        await this.verifyMeetLink()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async showMeetRoomByName () {
      // const self = this
      // const user = this.$user
      // await this.$rf.getRequest('AuthRequest').showMeetRoomByName(this.$route.params.name).then(r => {
      //   self.meet_room = r.data
      //   self.members = r.data.members
      //   self.is_member = !!self.members.find(m => m.user_id === user?.id)
      // }).finally(() => {
      //   return true
      // })
    },
    async verifyMeetLink () {
      const self = this
      const user = this.$user
      const params = {
        email: this.$route.query.email,
        roomName: this.$route.params.name,
        hash: this.$route.query.hash
      }
      await this.$rf
        .getRequest('AuthRequest')
        .verifyMeetLink(params)
        .then(r => {
          if (r.data) {
            self.verified = true
            if (user && user.email === self.$route.query.email) {
              self.$router.push({
                name: 'MeetRoom',
                params: { name: self.$route.params.name }
              })
            } else {
              appUtils.removeLocalToken()
              appUtils.removeLocalUser()
              self.onSendOtp()
              self.login_otp = true
            }
          } else {
            self.$toast.open({
              message: 'Link không tồn tại',
              type: 'warning'
              // all other options
            })
            self.backToList()
          }
        })
        .finally(() => {
          return true
        })
    },
    async onSendOtp () {
      const params = {
        email: this.$route.query.email
      }
      await this.$rf.getRequest('AuthRequest').sendOtpEmail(params)
    },
    async onLogin () {
      const self = this
      const params = {
        email: this.$route.query.email,
        otp: this.otp
      }
      try {
        let resp = await this.$rf.getRequest('AuthRequest').loginOtp(params)
        if (resp.data.access_token) {
          appUtils.setLocalToken(resp.data.access_token)

          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            appUtils.setLocalUser(resp.data)
            const lang = appUtils.getLangCode(resp.data.lang) || 'vi'
            this.$lang = lang
            appUtils.setLocalLang(lang)
          }

          self.loading = false

          setTimeout(() => {
            window.location.href = `/#/meet/${self.$route.params.name}`
          }, 300)
        }
      } catch (e) {
        self.$toast.open({
          message: 'Sai OTP',
          type: 'warning'
          // all other options
        })
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
}
.login-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  padding: 16px;
}
.login-form {
  width: 100%;
  max-width: 500px;
}
</style>
