<template>
  <div>
    <div class="h-100vh" :class="step == 2 ? 'bg-252A30' : ''">
      <Navbar v-if="step == 1" />

      <!-- locked -->
      <div class="px-4 pt-3" v-if="locked && !loading">
        <!-- <div class="d-flex align-items-center">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="me-2"
import ModalUpdatePassword from './ModalUpdatePassword.vue'
        >
          <path
            d="M30.5401 19.0954C29.9851 19.0954 29.5201 18.6754 29.4601 18.1204C29.1001 14.8204 27.3301 11.8504 24.6001 9.96035C24.1051 9.61535 23.9851 8.94035 24.3301 8.44535C24.6751 7.95035 25.3501 7.83035 25.8451 8.17535C29.1001 10.4404 31.2001 13.9804 31.6351 17.8954C31.6951 18.4954 31.2601 19.0354 30.6601 19.0954C30.6151 19.0954 30.5851 19.0954 30.5401 19.0954Z"
            fill="#476DD6"
          />
          <path
            d="M5.6097 19.1698C5.5797 19.1698 5.5347 19.1698 5.5047 19.1698C4.9047 19.1098 4.4697 18.5698 4.5297 17.9698C4.9347 14.0548 7.0047 10.5148 10.2297 8.23481C10.7097 7.88981 11.3997 8.00981 11.7447 8.48981C12.0897 8.98481 11.9697 9.65981 11.4897 10.0048C8.7897 11.9248 7.0347 14.8948 6.7047 18.1798C6.6447 18.7498 6.1647 19.1698 5.6097 19.1698Z"
            fill="#476DD6"
          />
          <path
            d="M23.9851 31.65C22.1401 32.535 20.1602 32.985 18.0902 32.985C15.9302 32.985 13.8752 32.505 11.9552 31.53C11.4152 31.275 11.2052 30.615 11.4752 30.075C11.7302 29.535 12.3902 29.325 12.9302 29.58C13.8752 30.06 14.8802 30.39 15.9002 30.585C17.2802 30.855 18.6902 30.87 20.0702 30.63C21.0902 30.45 22.0952 30.135 23.0252 29.685C23.5802 29.43 24.2401 29.64 24.4801 30.195C24.7501 30.735 24.5401 31.395 23.9851 31.65Z"
            fill="#476DD6"
          />
          <path
            d="M18.075 3.01514C15.75 3.01514 13.845 4.90514 13.845 7.24514C13.845 9.58514 15.735 11.4751 18.075 11.4751C20.415 11.4751 22.305 9.58514 22.305 7.24514C22.305 4.90514 20.415 3.01514 18.075 3.01514Z"
            fill="#476DD6"
          />
          <path
            d="M7.57497 20.8052C5.24997 20.8052 3.34497 22.6952 3.34497 25.0352C3.34497 27.3752 5.23497 29.2652 7.57497 29.2652C9.91497 29.2652 11.805 27.3752 11.805 25.0352C11.805 22.6952 9.89997 20.8052 7.57497 20.8052Z"
            fill="#476DD6"
          />
          <path
            d="M28.4248 20.8052C26.0998 20.8052 24.1948 22.6952 24.1948 25.0352C24.1948 27.3752 26.0848 29.2652 28.4248 29.2652C30.7648 29.2652 32.6548 27.3752 32.6548 25.0352C32.6548 22.6952 30.7648 20.8052 28.4248 20.8052Z"
            fill="#476DD6"
          />
        </svg>
        <p class="fs-24 fw-500 txt-pri mb-0">Hội chẩn</p>
      </div>-->

        <div class="my-4">
          <div
            class="d-flex align-items-center text-black-50 c-pointer"
            @click="backToList"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="me-2"
            >
              <path
                d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
                fill="#616673"
              />
            </svg>
            <span>{{ $t("mdt_room.lbl_back_list") }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2">
            <p class="fs-24 fw-500 txt-black text-center">
              {{ $t("mdt_room.btn_join_mdt") }}
            </p>
            <p class="fs-24 fw-500 txt-black text-center" v-if="mdt_room">
              {{ mdt_room.name }}
            </p>
            <p class="fs-18 txt-black text-center mb-4" v-if="mdt_room">
              {{ mdtUtils.getTimeStringDetail(mdt_room) }}
            </p>
          </div>
          <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
            <p class="fs-18">{{ $t("mdt_room.lbl_enter_pwd") }}</p>
            <input
              type="text"
              v-model="form.password"
              class="form-control border mb-4"
              @keyup.enter="joinMdtRoom"
            />
            <button
              class="btn btn-primary bg-pri bd-pri w-100"
              @click="joinMdtRoom"
            >
              {{ $t("mdt_room.lbl_confirm") }}
            </button>
          </div>
        </div>
      </div>
      <!-- locked -->
      <!-- unlocked -->
      <div v-if="!locked && !loading">
        <div v-if="step == 1 && !loading">
          <div class="town-hall">
            <div class="town-hall-track">
              <PrepareTrack
                :localStream="local_stream"
                :hasAudio="has_audio"
                :hasVideo="has_video"
                :camStatus="cam_status"
                :micStatus="mic_status"
                @camChangeStatus="camChangeStatus"
                @micChangeStatus="micChangeStatus"
                @testSpk="testSpk"
                @startVideo="createLocalTracks"
              />
            </div>
            <div class="town-hall-info text-center" v-if="mdt_room">
              <p class="text-center fs-24 fw-500 mb-1">{{ mdt_room.name }}</p>
              <p class="text-center fs-18">
                {{ mdtUtils.getTimeStringDetail(mdt_room) }}
              </p>
              <!-- <p class="text-center fs-16 fw-500">
            Không có người nào khác ở đây
            <span class="fs-12 fw-400">(chức năng đang phát triển)</span>
            </p>-->
              <button
                class="btn btn-primary bg-pri bd-pri me-2"
                v-if="mdt_room"
                @click="join()"
              >
                {{ $t("meet_room.btn_join") }}
              </button>
              <!-- <button
              v-if="mdt_room"
              class="btn btn-light txt-pri box-shadow"
              @click="onShareScreen()"
              :disabled="!canScreenShare()"
            >
              Trình bày
            </button>-->
            </div>
          </div>
        </div>
        <div v-if="step == 2 && !loading" class="main-content">
          <div v-show="show_slide" class="main-content-item">
            <SlideShow
              :room="mdt_room"
              :diseases="mdt_diseases.data"
              :type="'mdt'"
              :loading="loading_current_disease"
              :present_language="present_language"
              :showing_id="showing_id"
              :camStatus="cam_status"
              :micStatus="mic_status"
              :hasAudio="has_audio"
              :hasVideo="has_video"
              :changing_cam="changing_cam"
              :changing_mic="changing_mic"
              :is_player="true"
              :is_master="true"
              :indicator="indicator"
              :room_members="room_members"
              :checkin_members="checkin_members"
              @showParticipant="show_participant = !show_participant"
              @showInfo="showInfo"
              @showFunctions="showFunctions"
              @setShowingId="setShowingId"
              @camChangeStatus="camChangeStatusSlideShow"
              @micChangeStatus="micChangeStatusSlideShow"
              @onDisconect="onDisconect"
              @showUpdateTTTQM="showUpdateTTTQM"
              @showUpdateTBBHC="showUpdateTBBHC"
              @setSlideShow="setSlideShow"
              @refresh="refreshDisease"
            />
          </div>
          <div>
            <MdtHoDoConf
              :mdt_diseases="mdt_diseases"
              :hasAudio="has_audio"
              :hasVideo="has_video"
              :camStatus="cam_status"
              :micStatus="mic_status"
              :screenStatus="screen_status"
              :room_info="mdt_room"
              :room_name="$route.params.name"
              :room_members="room_members"
              :show_info="show_info"
              :show_function="show_function"
              :disconect="disconect"
              :changing_cam="changing_cam"
              :changing_mic="changing_mic"
              :show_participant="show_participant"
              :show_slide="show_slide"
              :is_player="true"
              :camStatusSlideShow="camStatusSlideShow"
              :micStatusSlideShow="micStatusSlideShow"
              :present_language="present_language"
              @setChangingMic="setChangingMic"
              @setChangingCam="setChangingCam"
              @camChangeStatus="camChangeStatus"
              @micChangeStatus="micChangeStatus"
              @showInfo="showInfo"
              @showFunctions="showFunctions"
              @disconected="backToList"
              @hide="show_participant = false"
              @setSlideShow="setSlideShow"
              @changePresentLanguage="changePresentLanguage"
            />
          </div>
        </div>
        <div v-if="loading" class="loading-meet-room">
          <img
            src="../../../public/assets/images/gif/Loading.gif"
            width="212"
            height="212"
            alt
          />
        </div>
        <!-- hiden content -->
        <div class="info-wraper box-shadow" v-show="show_info">
          <div class="info-wraper-backdrop" @click="show_info ^= true"></div>
          <div class="info-wraper-content">
            <MdtInfo
              :mdt_room="mdt_room"
              :room_members="room_members"
              :checkin_members="checkin_members"
              :present_language="present_language"
              @hideInfo="hideInfo"
            />
          </div>
        </div>
        <div class="info-wraper box-shadow" v-show="show_function">
          <div
            class="info-wraper-backdrop"
            @click="show_function ^= show_function"
          ></div>
          <div class="info-wraper-content">
            <MdtDisease
              :mdt_diseases="mdt_diseases"
              :showing_id="showing_id"
              :loading="loading_current_disease"
              :present_language="present_language"
              @hideDisease="hideFunctions"
              @refresh="refreshDisease"
              @showDiseaseDetail="showDiseaseDetail"
              @getMdtDiseases="onGetMdtDiseases"
              @showUpdateTTTQM="showUpdateTTTQM"
              @showUpdateTBBHC="showUpdateTBBHC"
            />
          </div>
        </div>
        <div class="audio d-none">
          <audio ref="audioTest" id="spk-test" preload="auto">
            <source
              src="../../../public/assets/audio/test_spk.mp3"
              type="audio/mp3"
            />
          </audio>
        </div>

        <div
          class="modal fade"
          id="modalTTTQM"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalTTTQMLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl"
            role="document"
            v-show="mdt_disease_for_concluding"
          >
            <div class="modal-content p-3">
              <div
                class="d-flex justify-content-between pb-3 border-bottom mb-3"
              >
                <h5 class="modal-title" id="modalTTTQMLabel">
                  {{ $t("mdt_room.lbl_surgery_report") }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <TTTQMForm
                :curr_disease="mdt_disease_for_concluding || {}"
                :room_info="mdt_room"
                :checkin_members="checkin_members"
                :present_language="present_language"
                @refresh="
                  () => {
                    refreshDisease();
                    handleCloseModal();
                  }
                "
              />
            </div>
          </div>
        </div>
        <BienBan
          :room_members="room_members"
          :curr_disease="mdt_disease_for_concluding"
          :room_info="mdt_room"
          :checkin_members="checkin_members"
          :present_language="present_language"
          @refresh="
            () => {
              refreshDisease();
              handleCloseModal();
            }
          "
        />
      </div>
      <!-- unlocked -->

      <SupportBrowser />
      <ModalAddUsername @onClose="handleAddUsernameSuccess" />
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'

import Navbar from '../../components/layouts/Navbar.vue'
import SupportBrowser from '../../components/PopupNotification/SupportBrowser.vue'
import MdtInfo from '../../components/mdts/MdtInfo.vue'
import MdtDisease from '../../components/mdts/MdtDisease.vue'
import TTTQMForm from '../../components/mdts/TTTQMForm.vue'
import BienBan from '../../components/mdts/BienBan.vue'
import SlideShow from '../../components/Ultis/SlideShow.vue'
import PrepareTrack from '../../components/meets/PrepareTrack.vue'
import MdtHoDoConf from '../../components/TwilioComponent/MdtHoDoConf.vue'
import ModalAddUsername from './ModalAddUsername.vue'
import ModalUpdatePassword from './ModalUpdatePassword.vue'

export default {
  name: 'MdtRoom',
  components: {
    Navbar,
    SupportBrowser,
    MdtInfo,
    MdtDisease,
    TTTQMForm,
    BienBan,
    PrepareTrack,
    MdtHoDoConf,
    SlideShow,
    ModalAddUsername,
    ModalUpdatePassword
  },
  data () {
    return {
      mdt_room: null,
      room_members: [],
      checkin_members: [],
      mdt_diseases: [],
      step: 1,
      locked: false,
      loading: false,
      mdtUtils,
      form: {
        password: ''
      },
      params_disease: {
        sort: 'created_at',
        sort_by: 'asc',
        status: null,
        search: '',
        page: 0,
        loading: false
      },
      whisper_room: null,
      room_name: '',
      curr_disease: null,
      is_support_browser: false,
      has_video: false,
      has_audio: false,
      has_devices: [],
      show_info: false,
      show_function: false,
      mdt_disease_for_concluding: null,
      mic_status: false,
      cam_status: false,
      screen_status: false,
      local_stream: null,
      show_participant: false,
      showing_id: null,
      loading_current_disease: false,
      disconect: 0,
      dr_member: null,
      changing_mic: true,
      changing_cam: true,
      indicator: '',
      show_slide: false,
      micStatusSlideShow: false,
      camStatusSlideShow: false,
      present_language: 'vi',
      isLoading: false
    }
  },
  watch: {
    $echo (e) {
      if (e && this.whisper_room) {
        this.onConnectSocket()
      }
    },
    mdt_diseases (data) {
      // this.onConnectSocket()
    }
  },
  mounted () {
    const self = this
    this.present_language = this.$lang || 'vi'
    if (!this.$user) {
      this.$targetRoute = `/#/mdt/${this.$route.params.name}`
      this.$router.push({ name: 'Login' })
      return false
    }
    if (!this.$route.params.name) {
      this.backToList()
      return false
    }
    this.room_name = this.$route.params.name
    this.$nextTick(function () {
      window.$('#modalSuportBrowser').on('hidden.bs.modal', function (e) {
        self.is_support_browser = false
        self.backToList()
      })
    })
    this.doctor_id = this.$user?.doctor?.id
    self.has_devices = []
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      self.onShowModalSpBr(true)
    } else {
      self.is_support_browser = true
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            self.has_devices.push(device.kind)
          })
        })
        .then(() => {
          self.has_video = !!self.has_devices.includes('videoinput')
          self.has_audio = !!self.has_devices.includes('audioinput')
          if (
            !navigator.userAgent.search('Safari') >= 0 ||
            !navigator.userAgent.search('Chrome') < 0
          ) {
            self.showMdtRoomByName(self.$route.params.name)
          }
        })
        .catch(function (err) {
          console.log(err.name + ': ' + err.message)
        })
    }
  },
  methods: {
    async getMedicalRecordMaster (id) {
      if (!id) return

      const self = this

      try {
        const resp = await self.$rf
          .getRequest('DoctorRequest')
          .getDetailBADT(id)

        if (!resp?.data) {
          return
        }

        const medicalRecordMain = resp.data?.medical_record_master
        const person = {
          ...resp.data.person,
          medical_record_name: resp.data.medical_record_name,
          treat_doctor: resp.data.treat_doctor_name,
          main_doctor: resp.data.main_doctor_name,
          status: resp.data.status,
          ref_id: resp.data.ref_id,
          person_ref_id: resp.data.person_ref_id
        }
        if (medicalRecordMain && medicalRecordMain.admission_room) {
          person.admission_room = medicalRecordMain.admission_room
        }
        if (medicalRecordMain && medicalRecordMain.admission_bed) {
          person.admission_bed = medicalRecordMain.admission_bed
        }

        self.$store.commit('setPerson', { person })
      } catch (error) {
        console.log(error)
      }
    },
    backToList () {
      const user = this.$user
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.hodo.app/#/hoi-chan'
      } else {
        dm = `${user?.domain || 'https://hodo.app'}/#/hoi-chan`
      }
      window.location.href = dm
    },
    //
    async showMdtRoomByName (name) {
      const self = this
      if (!name) return
      self.loading = true
      await self.$rf
        .getRequest('DoctorRequest')
        .showMdtRoomByName(name)
        .then((r) => {
          self.mdt_room = r.data
          self.locked = r.data.locked
          if (!self.locked) {
            self.createLocalTracks()
            self.onConnectSocket()
          }
        })
        .catch(() => {
          this.backToList()
          this.$toast.open({
            message: this.$t('mdt_room.lbl_getroom_fail'),
            type: 'error'
          })
        })
        .finally(() => {
          self.loading = false
        })
    },
    //
    async joinMdtRoom () {
      const self = this
      if (!this.mdt_room) return
      if (!this.form.password) {
        alert(this.$t('mdt_room.lbl_warn_enter_pwd'))
        return
      }
      const params = {
        password: this.form.password
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .joinMdtRoom(this.mdt_room.id, params)
        .then((r) => {
          self.locked = false
          self.createLocalTracks()
          self.onConnectSocket()
        })
        .catch(() => {})
    },
    //
    async getMdtMembers (id) {
      const self = this
      const params = {
        date: this.moment().format('DD-MM-YYYY')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getMdtMembers(id, params)
        .then((r) => {
          self.dr_member = r.data.find((d) => d.doctor_id === this.doctor_id)
          self.room_members = r.data
        })
    },
    //
    async mdtCheckin (id) {
      const params = {
        mdt_room_id: id,
        status: 1
      }
      await this.$rf.getRequest('DoctorRequest').mdtCheckin(params)
    },
    async getMdtCheckinMembers (id) {
      const self = this
      const params = {
        date: this.moment().format('YYYY-MM-DD'),
        mdt_room_id: id,
        limit: 1000
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getMdtCheckinMembers(params)
        .then((r) => {
          self.checkin_members = r.data
        })
    },
    //
    async getMdtDiseases (id, isNext) {
      const self = this
      this.params_disease.loading = true
      const page = 1
      // if (!isNext) {
      //   this.mdt_diseases = []
      // } else {
      //   page = this.params_disease.page + 1
      // }
      const params = {
        mdt_room_id: id,
        limit: 1000,
        sort: this.params_disease.sort,
        sort_by: this.params_disease.sort_by,
        page: page,
        date: this.moment().format('YYYY-MM-DD')
      }
      if (this.params_disease.search) {
        params.search = this.params_disease.search
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .getMdtDiseases(params)
        .then((r) => {
          // if (r && r.data) {
          //   self.params_disease.page = r.data.current_page
          //   self.mdt_diseases.current_page = r.data.current_page
          //   if (!isNext) {
          //     self.mdt_diseases = r.data
          //   } else {
          //     if (r.data?.count) {
          //       const old = self.mdt_diseases.data
          //       const newObj = r.data.data
          //       self.mdt_diseases.data = old.concat(newObj)
          //     }
          //   }
          // }
          self.mdt_diseases = r.data
        })
        .finally(() => {
          self.params_disease.loading = false
        })
    },
    async getMdtDiseaseInProcess (id) {
      const self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .getMdtDiseaseInProcess(id)
        .then((r) => {
          self.curr_disease = r.data
          // self.showing_id = r.data?.id || null
        })
    },
    //
    async onConnectSocket () {
      const self = this
      // Have this in case you stop running your laravel echo server
      if (this.$echo && this.$user) {
        const user = this.$user
        self.whisper_room = this.$echo.private('twl_room.' + self.room_name)
        self.whisper_room
          .listen('RoomMessageWasReceived', function (_data) {
            const { message } = _data
            const { type, data } = JSON.parse(message)
            console.log(type, data)
            if (type === 'refreshMdtMember') {
              self.onRefreshMembers(data)
            }
            if (type === 'refreshMdtDiseases') {
              self.onUserChangeDiseaseStatus(data)
            }
            if (type === 'refreshCurrentMdtDiseases') {
              self.onUserChangeCurrentMdtDisease()
            }
          })
          .listenForWhisper('joinRoom', (e) => {
            if (e.id !== user.id) {
              self.onUserJoinRoom(e)
            }
            self.whisper_room.whisper('userOnline', {
              id: user.id,
              name: user?.doctor?.name
            })
            console.log('sendUserOnline', {
              id: user.id,
              name: user?.doctor?.name
            })
            self.whisper_room.whisper('changeSlideShow', {
              id: user.id,
              name: user?.doctor?.name,
              role: self.dr_member?.role,
              showing_id: self.showing_id
            })
            self.whisper_room.whisper('changeModePresent', {
              id: user.id,
              name: user?.doctor?.name,
              role: self.dr_member?.role,
              show_slide: self.show_slide
            })
            console.log('sendShangeSlideShow', {
              id: user.id,
              name: user?.doctor?.name,
              role: self.dr_member?.role,
              showing_id: self.showing_id
            })
            self.whisper_room.whisper('changePresentLanguage', {
              id: user.id,
              name: user?.doctor?.name,
              role: self.dr_member?.role,
              language: self.present_language
            })
            console.log('changePresentLanguage', {
              id: user.id,
              name: user?.doctor?.name,
              role: self.dr_member?.role,
              language: self.present_language
            })
          })
          .listenForWhisper('userOnline', (e) => {
            if (e.id !== user.id) {
              self.onUserJoinRoom(e)
            }
            if (e.id === user.id) {
              // alert('Tài khoản của bạn đã tham gia cuộc gọi này ở thiết bị khác! Vui lòng kiểm tra và thử lại.')
            }
          })
          .listenForWhisper('quitRoom', (e) => {
            if (e.id !== user.id) {
              console.log('quitRoom', e)
              self.onUserQuitRoom(e)
            }
          })
          .listenForWhisper('changeSlideShow', (e) => {
            if (e.id !== user.id) {
              console.log('changeSlideShow', e)
              // if (e.role === 4) {
              self.showing_id = e.showing_id
              self.indicator = e.name
              // }
            }
          })
          .listenForWhisper('changeModePresent', (e) => {
            if (e.id !== user.id) {
              console.log('changeModePresent', e)
              // if (e.role === 4) {
              self.show_slide = e.show_slide
              self.indicator = e.name
              // }
            }
          })
          .listenForWhisper('changePresentLanguage', (e) => {
            if (e.id !== user.id) {
              console.log('changePresentLanguage', e)
              // if (e.role === 4) {
              self.present_language = e.language
              self.indicator = e.name
              // }
            }
          })
      }
    },
    onRefreshMembers (userInfo) {
      this.getMdtCheckinMembers(this.mdt_room.id)
      this.getMdtMembers(this.mdt_room.id)
    },
    onUserChangeDiseaseStatus (userInfo) {
      this.getMdtDiseases(this.mdt_room.id)
    },
    onUserChangeCurrentMdtDisease () {
      this.getMdtDiseases(this.mdt_room.id)
      this.getMdtDiseaseInProcess(this.mdt_room.id)
    },
    onGetMdtDiseases (isNext, params) {
      this.params_disease.search = params.search
      this.params_disease.status = params.status
      this.getMdtDiseases(this.mdt_room.id, isNext)
    },
    //
    onUserJoinRoom (userInfo) {
      console.log(userInfo?.name + ' joined', userInfo)
    },
    onUserQuitRoom (userInfo) {
      console.log(userInfo?.name + ' left', userInfo)
    },
    //
    onShowModalSpBr (show) {
      window.$('#modalSuportBrowser').modal(show ? 'show' : 'hide')
    },
    //
    hideInfo () {
      this.show_info = false
    },
    showInfo () {
      this.show_info = !this.show_info
      this.hideFunctions()
    },
    hideFunctions () {
      this.show_function = false
    },
    showFunctions () {
      this.show_function = !this.show_function
      this.hideInfo()
    },
    //
    async refreshDisease () {
      await this.getMdtDiseases(this.mdt_room.id)
      await this.getMdtDiseaseInProcess(this.mdt_room.id)

      // this.onConnectSocket()
    },
    handleCloseModal () {
      this.showModalTTTQM(false)
      this.showModalTBBHC(false)
    },
    //
    async showUpdateTTTQM (d) {
      this.mdt_disease_for_concluding = d

      if (d && d?.medical_record?.id) {
        await this.getMedicalRecordMaster(d?.medical_record?.id)
      }

      setTimeout(() => {
        this.showModalTTTQM(true)
      }, 100)
    },
    showModalTTTQM (show) {
      window.$('#modalTTTQM').modal(show ? 'show' : 'hide')
    },
    async showUpdateTBBHC (d) {
      this.mdt_disease_for_concluding = d

      if (d && d?.medical_record?.id) {
        await this.getMedicalRecordMaster(d?.medical_record?.id)
      }

      setTimeout(() => {
        this.showModalTBBHC(true)
      }, 100)
    },
    showModalTBBHC (show) {
      window.$('#modalAddBienBan').modal(show ? 'show' : 'hide')
    },
    //
    micChangeStatus () {
      this.mic_status = !this.mic_status
    },
    camChangeStatus () {
      this.cam_status = !this.cam_status
    },
    micChangeStatusSlideShow () {
      this.micStatusSlideShow = !this.micStatusSlideShow
    },
    camChangeStatusSlideShow () {
      this.camStatusSlideShow = !this.camStatusSlideShow
    },
    screenChangeStatus () {
      this.screen_status = !this.screen_status
    },
    //
    onShareScreen () {
      this.screenChangeStatus()
      this.step = 2
    },
    isFirefox () {
      var mediaSourceSupport =
        !!navigator.mediaDevices.getSupportedConstraints().mediaSource
      var matchData = navigator.userAgent.match('Firefox')
      var firefoxVersion = 0
      if (matchData && matchData[1]) {
        firefoxVersion = parseInt(matchData[1], 10)
      }
      return mediaSourceSupport && firefoxVersion >= 52
    },
    isChrome () {
      return 'chrome' in window
    },
    canScreenShare () {
      return this.isFirefox() || this.isChrome()
    },
    //
    testSpk () {
      this.$refs.audioTest.play()
    },
    //
    showDiseaseDetail (id) {
      const user = this.$user
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = `https://dev.hodo.app/#/doctor/mdt-disease/${id}?token=${appUtils.getLocalToken()}`
      } else {
        dm = `${
          user?.domain || 'https://hodo.app'
        }/#/doctor/mdt-disease/${id}?token=${appUtils.getLocalToken()}`
      }
      window.open(dm, '_blank')
    },
    goToRoom () {
      const self = this
      self.getMdtMembers(self.mdt_room.id)
      self.getMdtCheckinMembers(self.mdt_room.id)
      self.getMdtDiseases(self.mdt_room.id)
      self.mdtCheckin(self.mdt_room.id)
      self.getMdtDiseaseInProcess(self.mdt_room.id)
    },
    //
    createLocalTracks () {
      const self = this
      const params = {
        audio: false,
        video: false
      }
      if (self.has_video) {
        params.video = { width: 1280, height: 720 }
        self.cam_status = true
      }
      if (self.has_audio) {
        params.audio = true
        self.mic_status = true
      }
      navigator.mediaDevices
        .getUserMedia(params)
        .then((stream) => {
          self.local_stream = stream
        })
        .catch(() => {
          self.cam_status = false
          self.mic_status = false
          self.has_audio = false
          self.has_video = false
        })
    },
    join () {
      const user = this.$user
      if (!user.name) {
        this.handleShowModalAddUsername(true)
        return
      }

      this.goToRoom()
      if (this.local_stream) {
        this.local_stream.getTracks().forEach((t) => t.stop())
      }
      this.local_stream = null
      this.step = 2

      setTimeout(() => {
        this.whisper_room.whisper('joinRoom', {
          id: user.id,
          name: user?.doctor?.name
        })
        console.log('sendJoinRoom', {
          id: user.id,
          name: user?.doctor?.name
        })
      }, 1000)
      // if (this.dr_member?.role === 4) {
      // this.indicator = ''
      // this.whisper_room.whisper('changeSlideShow', {
      //   id: user.id,
      //   name: user?.doctor?.name,
      //   role: this.dr_member?.role,
      //   showing_id: this.showing_id
      // })
      // console.log('sendShangeSlideShow', {
      //   id: user.id,
      //   name: user?.doctor?.name,
      //   role: this.dr_member?.role,
      //   showing_id: this.showing_id
      // })
      // }
    },
    setShowingId (id) {
      const user = this.$user
      this.showing_id = id
      this.indicator = ''
      // if (this.dr_member?.role === 4) {
      this.whisper_room.whisper('changeSlideShow', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        showing_id: id
      })
      console.log('sendShangeSlideShow', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        showing_id: id
      })
      // }
    },
    setSlideShow (status) {
      const user = this.$user
      this.showing_id = status
      this.indicator = ''
      // if (this.dr_member?.role === 4) {
      this.show_slide = status
      this.whisper_room.whisper('changeModePresent', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        show_slide: status
      })
      console.log('changeModePresent', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        show_slide: status
      })
      // } else {
      //   alert('Bạn không có quyền thay đổi mode trình chiếu')
      // }
    },
    changePresentLanguage (code) {
      const user = this.$user
      this.indicator = ''
      // if (this.dr_member?.role === 4) {
      this.present_language = code
      this.whisper_room.whisper('changePresentLanguage', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        language: code
      })
      console.log('changePresentLanguage', {
        id: user.id,
        name: user?.doctor?.name,
        role: this.dr_member?.role,
        language: code
      })
    },
    onDisconect () {
      setTimeout(() => {
        this.disconect++
      })
    },
    setChangingMic (stt) {
      this.changing_mic = stt
    },
    setChangingCam (stt) {
      this.changing_cam = stt
    },
    handleShowModalAddUsername (show) {
      window.$('#modalAddUsername').modal(show ? 'show' : 'hide')
    },
    handleAddUsernameSuccess () {
      this.handleShowModalAddUsername(false)
      this.join()
    }
  },
  beforeDestroy () {
    this.onDisconect()
  }
}
</script>

<style scoped>
.town-hall-track {
  width: 100%;
  max-width: 700px;
}
.town-hall {
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  flex-wrap: wrap;
  margin: 0 auto;
}
.town-hall-info {
  width: 100%;
  max-width: 400px;
}
.bg-252A30 {
  background-color: #252a30 !important;
}
.h-100vh {
  height: 100vh;
}
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
}
.town-hall-info .btn {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  font-weight: 500;
}
.info-wraper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.info-wraper-content {
  padding: 16px;
  width: 546px;
  height: 100vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.info-wraper-backdrop {
  width: calc(100% - 546px);
  height: 100vh;
}

.disease-in-process-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.btn-header {
  width: 142px;
  max-width: 30vw;
}
.mdt-paticipant-wraper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.mdt-paticipant-backdrop {
  width: calc(100% - 259px);
  height: 100vh;
}
/* .mdt-paticipant-content {
  height: 100vh;
  width: 259px;
  padding: 16px;
  background: #fff;
} */
.main-content {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.main-content-item {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
