<template>
  <div class="h-100vh">
    <div v-if="loading" class="loading-meet-room">
      <img src="../../../public/assets/images/gif/Loading.gif" width="212" height="212" alt />
    </div>
    <div v-if="!loading && login_otp">
      <div class="login-wraper">
        <form @submit.prevent="onLogin" class="login-form border p-3 box-shadow radius-1r">
          <div>
            <div class="text-black mb-5">
              <p class="fs-24 fw-500">{{$t('log_in.lbl_otpsent_msg')}}</p>
            </div>
            <div class="text-start">
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  required
                  aria-describedby="emailHelp"
                  v-model="otp"
                />
              </div>
              <div class="row mt-4">
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary bg-pri bd-pri" :disabled="loading">
                    <span
                      class="spinner-border spinner-border-sm me-2"
                      role="status"
                      v-if="loading"
                    ></span>
                    {{$t('log_in.btn_send_otp')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'WebAppJoin',
  components: {},
  data () {
    return {
      meet_room: null,
      members: [],
      loading: false,
      is_member: false,
      verified: false,
      login_otp: false,
      otp: ''
    }
  },
  async created () {
    this.loading = true

    this.logOut()
    if (this.$route.query.token) {
      await this.onLogin(this.$route.query.token)
    } else {
      this.$targetRoute = `/#/meet/${this.$route.params.name}`
      this.$router.push({ name: 'Login' })
    }
  },
  mounted () {},
  methods: {
    logOut () {
      appUtils.removeLocalToken()
      appUtils.removeLocalUser()
      appUtils.removeClinicId()
      appUtils.removeDoctorClinic()
      appUtils.removeFirebaseAccessToken()
    },
    backToList () {
      this.$router.push({ name: 'Home' })
    },
    async showMeetRoomByName () {
      const self = this

      try {
        const resp = await self.$rf
          .getRequest('AuthRequest')
          .showMeetRoomByName(self.$route.params.name)

        if (resp?.data) {
          self.meet_room = resp.data
          self.members = resp.data?.members
          window.location.href = `/#/meet/${self.$route.params.name}`
        }
      } catch (error) {
        console.log(error)
        self.$toast.open({
          message: 'Phòng không tồn tại',
          type: 'warning'
        })
        self.$router.push({ name: 'Home' })
      } finally {
        self.loading = false
      }
    },
    async onLogin (token) {
      const self = this
      const name = self.$route.params.name

      if (!token) return

      try {
        self.loading = true
        appUtils.setLocalToken(token)

        const resp = await self.$rf.getRequest('AuthRequest').me()

        if (resp?.data) {
          await appUtils.setLocalUser(resp.data)
          await self.showMeetRoomByName()
        }
      } catch (e) {
        console.log(e)
        self.$toast.open({
          message: 'Token hết hạn vui lòng đăng nhập lại',
          type: 'warning'
        })
        appUtils.setLocalToken(null)
        self.$targetRoute = `/#/meet/${name}`
        window.location.href = '/#/login'
      }
    }
  }
}
</script>

<style scoped>
.loading-meet-room {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background-color: #252a30 !important;
  position: absolute;
  top: 0;
}
.login-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  padding: 16px;
}
.login-form {
  width: 100%;
  max-width: 500px;
}
</style>
