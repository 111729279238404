<template>
  <div class="create-room-container p-3">
    <div class="row mb-3">
      <div class="col-md-6">
        <div class="mb-3">
          <input
            type="text"
            class="form-control radius-0 border-0 border-bottom fs-28"
            v-model="form.name"
            placeholder="Thêm tiêu đề"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <div class="d-flex">
              <input
                type="date"
                class="form-control w-50 bg--muted border-0 radius-10 me-1"
                v-model="form.date"
                :min="today"
              />
              <input
                type="time"
                class="form-control w-50 bg--muted border-0 radius-10"
                v-model="form.start_time"
              />
            </div>
          </div>
          <div class="col-6">
            <select
              v-model="form.repeat_type"
              class="form-select bg--muted border-0 radius-10"
            >
              <option :value="0">Không lặp lại</option>
              <option :value="1">Hàng ngày</option>
              <option :value="2">Hàng tuần</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <input
            type="text"
            class="form-control radius-0 border-0 border-bottom"
            v-model="form.location"
            placeholder="Thêm vị trí"
          />
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <div class="member-wraper">
          <div class="border-bottom mb-3">
            <span
              class="mb-0 txt-pri bd-pri fs-14 fw-500"
              style="border-bottom: 3px solid"
              >Khách</span
            >
          </div>
          <!-- <div class="mb-3">
          <p class="mb-2 fs-14 fw-500">Thành viên định kỳ</p>
          </div>-->
          <div
            class="mb-3 dropdown"
            @focusout="focusOutSearchDoctor"
            @focusin="show_list_mem = true"
          >
            <input
              type="text"
              class="form-control"
              v-model="search"
              @keyup.enter="selectEmail()"
            />
            <ul
              class="dropdown-menu w-100 box-member dropdown-menu-list-dtr"
              :class="show_list_mem ? 'show' : ''"
              aria-labelledby="dropdownMenuButton2"
              :set="(drs = filteredAndSorted)"
            >
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:;"
                  v-for="dr in drs"
                  :key="dr.id"
                  @click="selectDr(dr)"
                >
                  <span
                    v-if="dr.user && dr.user.avatar"
                    class="img-avatar"
                    :style="
                      'background-image: url(' +
                      getUrlImage(dr.user.avatar) +
                      ');'
                    "
                  ></span>
                  <span v-if="dr.user && !dr.user.avatar" class="img-avatar">{{
                    dr.name
                      ? dr.name.charAt(0)
                      : dr.user.email
                      ? dr.user.email.charAt(0)
                      : "H"
                  }}</span>
                  <span class="list-item-name">
                    <span class="d-block mb-0 fs-14">{{ dr.name }}</span>
                    <span class="fs-12">{{ dr.user && dr.user.email }}</span>
                  </span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item text-center"
                  href="javascript:;"
                  @click="selectEmail()"
                  v-if="!drs || !drs.length"
                  >+ Thêm</a
                >
              </li>
            </ul>
          </div>
          <div class="mb-3">
            <div
              class="d-flex align-items-center mb-2"
              href="javascript:;"
              v-for="(user, i) in members"
              :key="i + 'selected-mem'"
            >
              <span
                v-if="user && user.avatar"
                class="img-avatar"
                :style="
                  'background-image: url(' + getUrlImage(user.avatar) + ');'
                "
              ></span>
              <span v-if="user && !user.avatar" class="img-avatar">{{
                user.name
                  ? user.name.charAt(0)
                  : user.email
                  ? user.email.charAt(0)
                  : ""
              }}</span>
              <span class="list-item-name">
                <span class="d-block mb-0 fs-14">{{ user && user.name }}</span>
                <span class="fs-12" v-if="user.id">{{
                  user && user.username
                }}</span>
              </span>
              <span class="c-pointer" @click="deselectDr(user)">x</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 text-end">
        <button
          :disabled="loading"
          class="btn btn-primary bg-pri px-4 bd-pri"
          @click="onSubmit()"
        >
          Lưu
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'CreateRoom',
  data () {
    return {
      form: {
        name: '',
        date: this.moment().format('YYYY-MM-DD'),
        start_time: null,
        end_time: '23:59:59',
        repeat_type: 0,
        location: '',
        password: ''
      },
      today: this.moment().format('YYYY-MM-DD'),
      show_list_mem: false,
      doctors: [],
      search: '',
      members: [],
      loading: false
    }
  },
  computed: {

    filteredAndSorted () {
      // function to compare names
      function compare (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }

      const result = this.doctors.filter(dr => {
        return appUtils.removeVietnameseTones(dr.name.toLowerCase()).includes(this.search.toLowerCase()) || (dr?.user?.username && appUtils.removeVietnameseTones(dr?.user?.username.toLowerCase()).includes(this.search.toLowerCase()))
      }).sort(compare)
      return result
    }
  },
  mounted () {
    if (this.$user?.doctor) this.getConectiveDoctors()
  },
  methods: {

    async onSubmit () {
      const self = this
      const form = {
        name: this.form.name,
        date: this.form.date,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
        repeat_type: this.form.repeat_type,
        location: this.form.location,
        password: this.form.password
      }
      if (!form.name) {
        alert('Vui lòng nhập tên phòng')
        return false
      }
      if (!form.date) {
        alert('Vui lòng chọn ngày diễn ra')
        return false
      } else {
        form.date = this.moment(form.date).format('DD-MM-YYYY')
      }
      if (!form.start_time) {
        alert('Vui lòng thời gian bắt đầu')
        return false
      }
      if (!form.start_time) {
        delete form.start_time
        delete form.end_time
      } else {
        form.start_time = this.moment(form.start_time, 'HH:mm').format('HH:mm:ss')
        // form.end_time = this.moment(form.end_time, 'HH:mm').format('HH:mm:ss')
      }
      self.loading = true
      await this.$rf.getRequest('AuthRequest').createMeetRoom(form).then((r) => {
        self.getCredential(r?.data?.id, r?.data?.room?.room_name)
      }).catch((e) => {
        console.log(e)
        self.loading = false
      })
    },
    async getConectiveDoctors () {
      const self = this
      const params = {
        circle: 1
      }
      await this.$rf.getRequest('DoctorRequest').getConectiveDoctors(params).then((r) => {
        self.doctors = r.data
        const dw = r.data?.workspace
        const dc = r.data?.circle?.map(dc => { return Object.assign({ circle: 1 }, dc) })
        if (dw && dc) {
          self.doctors = dw.concat(dc)
        } else if (dw) {
          self.doctors = dw
        } else if (dc) {
          self.doctors = dc
        }
        const user = this.$user
        self.doctors = self.doctors.filter(item => {
          return item.user_id !== user.id
        })
      })
    },
    async getCredential (id, roomName) {
      const self = this
      if (this.members && this.members.length) {
        const credentials = []
        this.members.forEach(m => {
          credentials.push({ email: m.username, roomName: roomName, fullName: m.name })
        })
        await this.$rf.getRequest('AuthRequest').getCredential({ credentials: credentials }).then((r) => {
          self.addMeetMembers(id, r.data)
        }).catch((e) => {
          console.log(e)
          self.loading = false
        })
      } else {
        this.finish()
      }
    },
    async addMeetMembers (id, credentials) {
      const self = this
      if (credentials && credentials.length) {
        const members = []
        credentials.forEach(c => {
          members.push({ user_id: c.userId })
        })
        await this.$rf.getRequest('AuthRequest').addMeetMembers(id, { members: members }).then((r) => {
          self.finish()
        }).catch((e) => {
          console.log(e)
          self.loading = false
        })
      } else {
        this.finish()
      }
    },
    finish () {
      this.$toast.open({
        message: 'Tạo phòng thành công',
        type: 'success'
        // all other options
      })
      this.loading = true
      this.$router.push({ name: 'Home' })
    },
    selectEmail () {
      if (this.search) {
        if (!this.validateEmail(this.search)) {
          alert('Vui lòng nhập đúng định dạng email')
          return
        }
        const user = {
          id: null,
          username: this.search,
          name: this.search
        }
        if (!this.members.find(u => u.username === user?.username)) {
          this.members.push(user)
        }
        setTimeout(() => {
          this.search = ''
        }, 50)
      }
    },
    selectDr (dr) {
      this.search = ''
      const user = dr.user
      if (!this.members.find(u => u.username === user?.username)) {
        this.members.push(user)
      }
    },
    deselectDr (user) {
      const indexUser = this.members.findIndex(u => u.username === user?.username)
      if (indexUser > -1) {
        this.members.splice(indexUser, 1)
      }
    },
    getUrlImage (path) {
      return appUtils.getImageURL(path)
    },
    focusOutSearchDoctor () {
      setTimeout(() => {
        this.show_list_mem = false
      }, 150)
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  }
}
</script>

<style scoped>
.bg--muted {
  background-color: rgb(243, 243, 243);
}

.member-wraper {
  width: 100%;
  max-width: 300px;
}

.box-member {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 10px;
}

.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #476dd6;
}

.list-item-name {
  width: calc(100% - 60px);
}

.list-item-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dropdown-menu-list-dtr {
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
